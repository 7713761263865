import './login.scss';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiURL}/auth/login`, {
        email,
        password,
      });
      const token = response.data.token;
      localStorage.setItem('token', token);
      navigate('/profile'); // Navigate to home after successful login
    } catch (error) {
      console.error('Login failed:', error.message);
      setError('Wrong credentials. Please try again.');
    }
  };

  return (
    <div className="login">
      <div className="left">
        <div className="wrapper">
          <form onSubmit={handleLogin}>
            <h2>MMS Login</h2>
            <div className="formInput">
              <label>Email</label>
              <input
                type="text"
                placeholder="johndoe@mail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="formInput">
              <label>Password</label>
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <div className="error">{error}</div>}
            <button type="submit">Login</button>
            <span>Forgot password</span>
          </form>
        </div>
      </div>
      <div className="right">
        <div className="top">
          <h1>Motorcycle Management System</h1>
        </div>
        <div className="bottom">
          {/* Add images or other content here if needed */}
        </div>
      </div>
    </div>
  );
};

export default Login;
