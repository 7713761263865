import React from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Correct import

const AuthRoute = ({ element }) => {
  const token = localStorage.getItem('token');

  // Check if token exists
  if (token) {
    try {
      // Decode the token and log the decoded information
      const decodedToken = jwtDecode(token);
      console.log('Decoded Token:', decodedToken);
      // You can also check specific claims if needed
      // console.log('Token Expiration:', decodedToken.exp);

      return element;
    } catch (error) {
      console.error('Token decoding failed:', error);
    }
  }

  // Redirect to login if no token or decoding fails
  return <Navigate to="/login" />;
};

export default AuthRoute;
