import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  TextField, Button, Grid, Typography, Container, Paper, Box, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import {jwtDecode} from 'jwt-decode';

const roleBasedStatusOptions = {
  'manager': [
    'approved', 'cancelled', 'pending', '1001', '3001', '3002', '3003', '3004', '3005', '3006', 
    '3017', '3027', '3037', '3047', '3057', '4001', '4002', '5001', '6001', '6002', '6003', 
    '6004', '7001', '8001'
  ],
  'admin': [],
  'account': ['Issue'],
  'sales': []
};

const EditCustomerLoan = () => {
  const { customerloandId } = useParams();
  const navigate = useNavigate();
  const [loan, setLoan] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    nricNo: '',
    mobileNumber: '',
    email: '',
    NoPlate: '',
    engine: '',
    chassisNo: '',
    gender: '',
    maritalStatus: '',
    race: '',
    address: '',
    status: ''
  });
  const [engines, setEngines] = useState([]);
  const [chassisNumbers, setChassisNumbers] = useState([]);
  const [filteredChassisNumbers, setFilteredChassisNumbers] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  // Retrieve and decode token
  const token = localStorage.getItem('token');
  let userRole = '';

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userRole = decodedToken.user.role;
    } catch (error) {
      console.error('Token decoding failed:', error);
    }
  }

  useEffect(() => {
    // Set status options based on user role
    setStatusOptions(roleBasedStatusOptions[userRole] || []);

    const fetchLoan = async () => {
      console.log('Fetching loan data for ID:', customerloandId); // Debugging line
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/customerloans/${customerloandId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        console.log('Fetched loan data:', response.data); // Debugging line
        const { _id, __v, createdAt, updatedAt, ...loanData } = response.data; // Destructure and exclude fields
        setLoan(response.data);
        setFormData(loanData);
      } catch (error) {
        console.error('Error fetching loan:', error);
      }
    };

    const fetchStockData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/stocks`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        const stockData = response.data;
        setEngines([...new Set(stockData.map(stock => stock.engineNumber))]); // Ensure unique engines
        setChassisNumbers(stockData);
        // Initialize filtered chassis numbers based on the default engine in formData
        const initialFilteredChassis = stockData
          .filter(stock => stock.engineNumber === formData.engine)
          .map(stock => stock.chassisNumber);
        setFilteredChassisNumbers(initialFilteredChassis);
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };

    fetchLoan();
    fetchStockData();
  }, [customerloandId, apiURL, userRole]);

  useEffect(() => {
    // Update filtered chassis numbers whenever the selected engine changes
    if (formData.engine) {
      const filteredChassis = chassisNumbers
        .filter(stock => stock.engineNumber === formData.engine)
        .map(stock => stock.chassisNumber);
      setFilteredChassisNumbers(filteredChassis);
    }
  }, [formData.engine, chassisNumbers]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Convert NoPlate field value to uppercase
    const newValue = name === 'NoPlate' ? value.toUpperCase() : value;

    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form with data:', formData); // Debugging line
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.put(`${apiURL}/customerloans/${customerloandId}`,
        formData, 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log('Loan updated:', response.data);
      // Redirect to another route or show a success message
      navigate('/customerloans'); // Change this to the desired route
    } catch (error) {
      console.error('Error updating loan:', error);
    }
  };

  return (
    <Container component={Paper} elevation={3} sx={{ padding: 3, maxWidth: 'md', marginTop: 5 }}>
      <Typography variant="h4" gutterBottom>
        Edit Loan
      </Typography>
      {loan ? (
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="name"
                value={formData.name || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="NRIC No"
                name="nricNo"
                value={formData.nricNo || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Engine</InputLabel>
                <Select
                  label="Engine"
                  name="engine"
                  value={formData.engine || ''}
                  onChange={handleInputChange}
                >
                  {engines.map((engine, index) => (
                    <MenuItem key={index} value={engine}>
                      {engine}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Chassis No</InputLabel>
                <Select
                  label="Chassis No"
                  name="chassisNo"
                  value={formData.chassisNo || ''}
                  onChange={handleInputChange}
                >
                  {filteredChassisNumbers.map((chassis, index) => (
                    <MenuItem key={index} value={chassis}>
                      {chassis}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="No Plate"
                name="NoPlate"
                value={formData.NoPlate || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={formData.email || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Gender"
                name="gender"
                value={formData.gender || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Marital Status"
                name="maritalStatus"
                value={formData.maritalStatus || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Race"
                name="race"
                value={formData.race || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address"
                name="address"
                value={formData.address || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  name="status"
                  value={formData.status || ''}
                  onChange={handleInputChange}
                >
                  {statusOptions.map((status, index) => (
                    <MenuItem key={index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ marginTop: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              Update
            </Button>
          </Box>
        </form>
      ) : (
        <Typography variant="h6">Loading loan details...</Typography>
      )}
    </Container>
  );
};

export default EditCustomerLoan;
