import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  TextField, Button, Grid, Typography, Container, Paper, Box, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';

const statusOptions = [
   'Ready','Claim',
  
];

const SaleEditCustomerLoan = () => {
  const { id: customerloandId } = useParams(); // Extract id from useParams
  const navigate = useNavigate();
  const [loan, setLoan] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    nricNo: '',
    mobileNumber: '',
    email: '',
    NoPlate: '',
    gender: '',
    maritalStatus: '',
    race: '',
    address: '',
    status: ''
  });

  useEffect(() => {
    const fetchLoan = async () => {
      console.log('Extracted customerloandId:', customerloandId); // Debugging line
      if (!customerloandId) {
        console.error('No customerloandId provided'); // Debugging line
        return;
      }
      console.log('Fetching loan data for ID:', customerloandId); // Debugging line
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/customerloans/${customerloandId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log('Fetched loan data:', response.data); // Debugging line
        const { _id, __v, createdAt, updatedAt, ...loanData } = response.data; // Destructure and exclude fields
        setLoan(response.data);
        setFormData(loanData);
      } catch (error) {
        console.error('Error fetching loan:', error);
      }
    };

    fetchLoan();
  }, [customerloandId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Convert NoPlate field value to uppercase
    const newValue = name === 'NoPlate' ? value.toUpperCase() : value;

    setFormData({ 
        ...formData, 
        [name]: newValue 
    });
};



  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form with data:', formData); // Debugging line
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/customerloans/${customerloandId}`,
        formData, // formData should be here
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log('Loan updated:', response.data);
      // Redirect to another route or show a success message
      navigate('/salesloans'); // Change this to the desired route
    } catch (error) {
      console.error('Error updating loan:', error);
    }
  };

  return (
    <Container component={Paper} elevation={3} sx={{ padding: 3, maxWidth: 'md', marginTop: 5 }}>
      <Typography variant="h4" gutterBottom>
        Edit Loan
      </Typography>
      {loan ? (
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="name"
                value={formData.name || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="NRIC Number"
                name="nricNo"
                value={formData.nricNo || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={formData.email || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="No Plate"
                name="NoPlate"
                value={formData.NoPlate || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="gender"
                name="gender"
                value={formData.gender || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="maritalStatus"
                name="maritalStatus"
                value={formData.maritalStatus || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Race</InputLabel>
                <Select
                  name="race"
                  value={formData.race || ''}
                  onChange={handleInputChange}
                  label="Race"
                >
                  <MenuItem value="Malay">Malay</MenuItem>
                  <MenuItem value="Chinese">Chinese</MenuItem>
                  <MenuItem value="Indian">Indian</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                value={formData.address || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                margin="normal"
               
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={formData.status || ''}
                  onChange={handleInputChange}
                  label="Status"
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary">
              Update Loan
            </Button>
          </Box>
        </form>
      ) : (
        <Typography variant="h6">Loading...</Typography>
      )}
    </Container>
  );
};

export default SaleEditCustomerLoan;
