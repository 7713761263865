import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from './ViewCustomerLoan.scss'; // Assuming you have your own stylesheet

const CustomerCashTable = ({
  data,
  columns,
  isCustomerLoanTable = false,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onMenuClick,
  onMenuClose,
  onAddReferenceClick,
  anchorEl
}) => {
  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className='customTableRow'>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.field} className={styles.tableCell}>{col.headerName}</TableCell>
            ))}
            <TableCell className={styles.tableCell}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((item) => (
            <TableRow key={item._id} className={styles.customTableRow}>
              {columns.map((col) => (
                <TableCell key={col.field} className={styles.tableCell}>
                  {col.render ? col.render(item[col.field]) : item[col.field]}
                </TableCell>
              ))}
              <TableCell className={styles.tableCell}>
                {isCustomerLoanTable ? (
                  <>
                    <MoreVertIcon onClick={(e) => onMenuClick(e)} />
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={onMenuClose}
                    >
                      <MenuItem onClick={() => onAddReferenceClick(item._id)}>Add Reference</MenuItem>
                    </Menu>
                  </>
                ) : (
                  <MoreVertIcon />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default CustomerCashTable;
