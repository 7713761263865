import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, MenuItem } from '@mui/material';
import { MobileNumberMask, NRICNoMask } from '../../pages/new/MobileNumberMask';

const AddReferenceDialog = ({ open, onClose, onChange, onSubmit, newReference }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Reference</DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          label="Name"
          value={newReference.name}
          onChange={onChange}
          fullWidth
          margin="dense"
        />
        <TextField
          name="nricNo"
          label="NRIC No"
          value={newReference.nricNo}
          onChange={onChange}
          fullWidth
          margin="dense"
          InputProps={{
            inputComponent: NRICNoMask
          }}
        />
        <TextField
          name="contactNumber"
          label="Contact Number"
          value={newReference.contactNumber}
          onChange={onChange}
          fullWidth
          margin="dense"
          InputProps={{
            inputComponent: MobileNumberMask
          }}
        />
        <TextField
          name="relationship"
          label="Relationship"
          value={newReference.relationship}
          onChange={onChange}
          fullWidth
          margin="dense"
          select
        >
          <MenuItem value="In-laws">In-laws</MenuItem>
          <MenuItem value="Parents">Parents</MenuItem>
          <MenuItem value="Siblings">Siblings</MenuItem>
          <MenuItem value="Spouse">Spouse</MenuItem>
          <MenuItem value="Child">Child</MenuItem>
          <MenuItem value="Relative">Relative</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddReferenceDialog;
