import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import List from '../../components/table/List';
import ViewCustomerCash from '../../components/table/ViewCustomerCash';
import ViewCustomerCashSales from '../../components/table/ViewCustomerCashSales';

const SalesCash = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
         <ViewCustomerCashSales />
      </div>
    </div>
  );
};

export default SalesCash;
