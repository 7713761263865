import './AddUserPage.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import AddUser from '../../components/userdata/AddUser';

const AddUserPage = () => {
  return (
    <div className="users">
      <Sidebar />
      <div className="userslist">
        <Navbar />
         <AddUser />
      </div>
    </div>
  );
};

export default AddUserPage;
