import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import List from './pages/list/List';
import Login from './pages/login/Login';
import Single from './pages/single/Single';
import NewCustomerLoan from './pages/new/NewCustomerLoan';
import NewCustomercash from './pages/new/NewCustomercash';
import Cash from './pages/cash/Cash';
import AuthRoute from './AuthRoute';
import Score from './pages/score/Score';
import AddScorePage from './pages/score/AddScorePage';
import Paymenterms from './pages/paymenterms/Paymenterms';
import PaymentermsEdit from './pages/paymenterms/PaymentermsEdit';
import EditLoan from './pages/loan/EditLoan';
import BikeModel from './pages/bikemodel/BikeModel';
import AddBikeModel from './pages/bikemodel/AddBikeModel';
import EditBikeModel from './pages/bikemodel/EditBikeModel';
import SalesLoan from './pages/salesloan/SalesLoan';
import SalesCash from './pages/salescash/SalesCash';
import Loan from './pages/loan/Loan';
import EditAttachment from './pages/loan/EditAttachment';
import Forecast from './pages/forecast/Forecast';
import UserProfile from './pages/home/UserProfile';
import SalesEditLoan from './pages/loan/SalesEditLoan';
import SaleEditCustomerLoan from './components/table/salesloandata/EditCustomerLoan';
import Stock from './pages/stock/Stock';
import AddStock from './pages/stock/AddStock';
import EditStock from './pages/stock/EditStock';
import EditFinance from './pages/loan/EditFinance';
import EditFinanceLoan from './components/table/loan/EditFinanceLoan';
import EditEmployment from './pages/loan/EditEmployment';
import EditEmploymentLoan from './components/table/loan/EditEmploymentLoan';
import SalesEditEmployment from './pages/loan/SalesEditEmployment';
import SalesEditFinance from './pages/loan/SalesEditFinance';
import EditFinanceStock from './pages/loan/EditFinanceStock';
import AddUserPage from './pages/single/AddUserPage';
import Activity from './pages/activity/Activity';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="/" element={<AuthRoute element={<Home />} />} />
          <Route path="/profile" element={<AuthRoute element={<UserProfile />} />} />
          <Route path="/forecast" element={<AuthRoute element={<Forecast />} />} />
          <Route
            path="users/*"
            element={<AuthRoute element={<List />} requiredRole="admin" />}
          >
            <Route path=":userId" element={<Single />} />
          </Route>
            <Route path="users/adduser" element={<AddUserPage />} />
          <Route
            path="customers/cashs/*"
            element={<AuthRoute element={<Cash />} requiredRole="sales" />}
          />
          <Route
            path="salescash/*"
            element={<AuthRoute element={<SalesCash />} requiredRole="sales" />}
          />
          <Route
            path="customers/cashs/addcustomercash"
            element={<AuthRoute element={<NewCustomercash />} requiredRole="sales" />}
          />
          <Route
            path="customerloans/*"
            element={<AuthRoute element={<Loan />} requiredRole="admin" />}
          />
          <Route
            path="customerloans/:customerloandId"
            element={<AuthRoute element={<EditLoan />} requiredRole="admin" />}
          />
          {/* <Route
            path="customerloans/employments/:id"
            element={<AuthRoute element={< EditEmploymentLoan/>} requiredRole="admin" />}
          /> */}
          <Route
            path="customerloans/employments/:id"
            element={<AuthRoute element={< EditEmployment/>} requiredRole="admin" />}
          />
          <Route
            path="customerloans/finances/:id"
            element={<AuthRoute element={< EditFinance/>} requiredRole="admin" />}
          />
          <Route
            path="customerloans/finance-stocks/:id"
            element={<AuthRoute element={< EditFinanceStock/>} requiredRole="admin" />}
          />
          <Route
            path="customerloans/addcustomerloan"
            element={<AuthRoute element={<NewCustomerLoan />} requiredRole="admin" />}
          />
          <Route
            path="customerloans/attachments/:attachmentId"
            element={<AuthRoute element={<EditAttachment />} requiredRole="admin" />}
          />
          <Route
            path="salesloans/*"
            element={<AuthRoute element={<SalesLoan />} requiredRole="sales" />}
          />
          <Route
            path="salesloans/:id"
            element={<AuthRoute element={<SalesEditLoan />} requiredRole="sales" />}
          />
          <Route
            path="salesloans/employments/:id"
            element={<AuthRoute element={<SalesEditEmployment />} requiredRole="sales" />}
          />
          <Route
            path="salesloans/finances/:id"
            element={<AuthRoute element={<SalesEditFinance />} requiredRole="sales" />}
          />
          {/* <Route
            path="salesloans/:id"
            element={<AuthRoute element={<SaleEditCustomerLoan />} requiredRole="sales" />}
          /> */}


          <Route
            path="scores/*"
            element={<AuthRoute element={<Score />} requiredRole="admin" />}
          />
          <Route
            path="scores/addscore"
            element={<AuthRoute element={<AddScorePage />} requiredRole="admin" />}
          />
          <Route
            path="paymenterms/*"
            element={<AuthRoute element={<Paymenterms />} requiredRole="admin" />}
          />
          <Route
            path="paymenterms/addpaymenterms"
            element={<AuthRoute element={<AddScorePage />} requiredRole="admin" />}
          />
          <Route
            path="paymenterms/:id"
            element={<AuthRoute element={<PaymentermsEdit />} requiredRole="admin" />}
          />
          <Route
            path="bikemodels/*"
            element={<AuthRoute element={<BikeModel />} requiredRole="admin" />}
          />
          <Route
            path="bikemodels/AddBikeModel"
            element={<AuthRoute element={<AddBikeModel />} requiredRole="admin" />}
          />
          <Route
            path="bikemodels/:id"
            element={<AuthRoute element={<EditBikeModel />} requiredRole="admin" />}
          />
       
        <Route
          path="stocks/*"
          element={<AuthRoute element={<Stock />} requiredRole="admin" />}
        />
        <Route
          path="stocks/AddStock"
          element={<AuthRoute element={<AddStock />} requiredRole="admin" />}
        />
        <Route
          path="stocks/:id"
          element={<AuthRoute element={<EditStock />} requiredRole="admin" />}
        />
            <Route
            path="activities/*"
            element={<AuthRoute element={<Activity />} requiredRole="admin" />}
          />
         </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
