import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import ScoreData from '../../components/scoredata/ScoreData';

const Score = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
        <ScoreData />
      </div>
    </div>
  );
};

export default Score;
