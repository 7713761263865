import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, MenuItem, Button, Typography, Container, Grid } from '@mui/material';
import {jwtDecode} from 'jwt-decode'; // Correct import
import './addUser.scss';

const roles = ['administrator', 'manager', 'admin', 'account', 'sales', 'credit_check'];

const AddUser = () => {
    const apiURL = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        role: 'manager',
        outletId: '',  // Initialize outletId as empty
    });

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            try {
                const decodedToken = jwtDecode(storedToken);
                console.log('Decoded Token:', decodedToken);

                const userId = decodedToken.user.id;
                if (userId) {
                    // Fetch user data to get outletId
                    axios.get(`${apiURL}/auth/users/${userId}`, {
                        headers: {
                            'Authorization': `Bearer ${storedToken}`,
                        },
                    })
                    .then(response => {
                        const userData = response.data;
                        console.log('User Data:', userData);
                        if (userData.outletId && userData.outletId._id) {
                            setFormData((prevData) => ({
                                ...prevData,
                                outletId: userData.outletId._id, // Set outletId in formData
                            }));
                        } else {
                            console.error('Outlet ID is missing in the user data.');
                            setError('Failed to retrieve Outlet ID from user data.');
                        }
                    })
                    .catch(err => {
                        console.error('Error fetching user data:', err);
                        setError('Failed to fetch user data.');
                    });
                } else {
                    console.error('User ID is missing in the token.');
                    setError('User ID is missing in the token.');
                }
            } catch (error) {
                console.error('Token decoding failed:', error);
                setError('Failed to decode token. Please log in again.');
            }
        } else {
            console.error('Token not found in localStorage.');
            setError('No token found. Please log in again.');
        }
    }, [apiURL]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.outletId) {
            setError('Outlet ID is missing. Please ensure you are logged in.');
            return;
        }
        try {
            const storedToken = localStorage.getItem('token');
            await axios.post(`${apiURL}/auth/register`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setSuccess('User added successfully!');
            setError(null);
            // Clear the form after successful submission
            setFormData({
                username: '',
                email: '',
                password: '',
                role: 'manager',
                outletId: formData.outletId, // Keep outletId from the token
            });
        } catch (err) {
            console.error('Error during user registration:', err);
            setError('Failed to add user. Please try again.');
            setSuccess(null);
        }
    };

    return (
        <Container maxWidth="md" className="addUser">
            <Typography variant="h4" gutterBottom>
                Add User
            </Typography>
            <form onSubmit={handleSubmit} className="addUserForm">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            type="email"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Role"
                            variant="outlined"
                            fullWidth
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            required
                        >
                            {roles.map((role) => (
                                <MenuItem key={role} value={role}>
                                    {role}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Outlet ID"
                            variant="outlined"
                            fullWidth
                            name="outletId"
                            value={formData.outletId}
                            onChange={handleChange}
                            required
                            disabled // Disable since it's set automatically
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ backgroundColor: '#2c9194', '&:hover': { backgroundColor: '#2c9294' } }}
                        >
                            Add User
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {error && <Typography color="error">{error}</Typography>}
            {success && <Typography color="primary">{success}</Typography>}
        </Container>
    );
};

export default AddUser;
