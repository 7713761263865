import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner component
import Doughnut from '../../components/doughnutChart/Doughnut';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Widget from '../../components/widget/Widget';
import './home.scss';
import { BarChart } from 'recharts';

const Home = () => {
  const [totalSubmission, setTotalSubmission] = useState(0);
  const [totalSalesValue, setTotalSalesValue] = useState(0);
  const [totalApprovedLoan, setTotalApprovedLoan] = useState(0);
  const [totalApprovedLoanAmount, setTotalApprovedLoanAmount] = useState(0);
  const [totalYearlyApprovedLoan, setTotalYearlyApprovedLoan] = useState(0);
  const [totalYearlyApprovedLoanAmount, setTotalYearlyApprovedLoanAmount] = useState(0);
  const [totalApprovedLoanYear, setTotalApprovedLoanYear] = useState(0);
  const [totalApprovedLoanAmountYear, setTotalApprovedLoanAmountYear] = useState(0);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const [totalOverdueAmount, setTotalOverdueAmount] = useState(0);
  const [totalPaidAmountThisMonth, settotalPaidAmountThisMonth] = useState(0);
  const [loading, setLoading] = useState(true); // State to track loading
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/pending-sales`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setTotalSubmission(response.data.totalCustomerLoans);
        setTotalSalesValue(response.data.totalSalesValue);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchTotalApprovedData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/total-approved`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setTotalApprovedLoanAmount(response.data.totalCustomerLoansApproved);
        setTotalApprovedLoan(response.data.totalApprovedLoan);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTotalApprovedData();
  }, []);

  useEffect(() => {
    const fetchTotalApprovedDataYear = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/total-approved-year`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setTotalApprovedLoanYear(response.data.totalCustomerLoansApprovedYear);
        setTotalApprovedLoanAmountYear(response.data.totalApprovedLoanYear);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTotalApprovedDataYear();
  }, []);

  useEffect(() => {
    const fetchTotalYearlyApprovedDataYear = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/pending-sale-year`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setTotalYearlyApprovedLoan(response.data.totalCustomerLoansYear);
        setTotalYearlyApprovedLoanAmount(response.data.totalSalesValueYear);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is done
      }
    };

    fetchTotalYearlyApprovedDataYear();
  }, []);

  useEffect(() => {
    const fetchTotalOverdueAmountData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/overdue`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setTotalCustomerCount(response.data.totalCustomerCount);
        setTotalOverdueAmount(response.data.totalOverdueAmount);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is done
      }
    };

    fetchTotalOverdueAmountData();
  }, []);
  useEffect(() => {
    const fetchTotalPaidAmountThisMonth = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/paid-montly`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        settotalPaidAmountThisMonth(response.data.totalPaidAmountThisMonth);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is done
      }
    };

    fetchTotalPaidAmountThisMonth();
  }, []);

  // Calculate the percentage of totalApprovedLoan out of target value (100,000)
  const targetValue = 100000; // Target value for 100%
  const percentagePaid = totalApprovedLoan > 0 
    ? (totalApprovedLoan / targetValue) * 100 
    : 0;

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        {loading ? (
          <div className="spinner">
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
          </div>
        ) : (
          <>
            <div className="widgets">
              <Widget type="user" amount={`${totalSubmission}`} diff={20} />
              <Widget type="balance" amount={`${totalApprovedLoanAmount}`} diff={50} />
              <Widget type="salesValue" amount={`${totalSalesValue}`} diff={30} />
              <Widget type="earning" amount={`${totalApprovedLoan}`} diff={40} />
            </div>
            <div className="widgets">
              <Widget type="userytd" amount={`${totalYearlyApprovedLoan}`} diff={20} />
              <Widget type="orderytd" amount={`${totalApprovedLoanYear}`} diff={30} />
              <Widget type="earningytd" amount={`${totalYearlyApprovedLoanAmount}`} diff={40} />
              <Widget type="balanceytd" amount={`${totalApprovedLoanAmountYear}`} diff={50} />
            </div>
            <div className="widgets">
              <Doughnut percentage={percentagePaid} />
              <BarChart />
            </div>
            <div className="widgets">
              <Widget type="overdueAmount" amount={`${totalCustomerCount}`} diff={20} />
              <Widget type="overdueCustomer" amount={`${totalOverdueAmount}`} diff={30} />
              <Widget  type="PaidAmount" amount={`${totalPaidAmountThisMonth.toFixed(2)}`} diff={30} />
              <Widget type="loanMonthlyRate"  amount={`${percentagePaid.toFixed(2)}%`} diff={30} />
              {/* Pass the calculated percentage to the Widget */}
              {/* <Widget type="PaidAmount" amount={`${percentagePaid.toFixed(2)}%`} diff={40} /> */}
              {/* <Widget type="LoanMonthlyRate" amount={`${percentagePaid.toFixed(2)}%`} diff={40} /> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
