import React from 'react';
import MaskedInput from 'react-text-mask';

// Mobile Number Mask Component
const MobileNumberMask = React.forwardRef(function MobileNumberMask(props, ref) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(maskedRef) => {
        if (typeof ref === 'function') {
          ref(maskedRef ? maskedRef.inputElement : null);
        } else if (ref) {
          ref.current = maskedRef ? maskedRef.inputElement : null;
        }
      }}
      mask={['+', '6', '0', '1', /\d/,'-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,/\d/]}
      placeholderChar={'\u2000'}
    />
  );
});
const PhoneNumberMask = React.forwardRef(function PhoneNumberMask(props, ref) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(maskedRef) => {
        if (typeof ref === 'function') {
          ref(maskedRef ? maskedRef.inputElement : null);
        } else if (ref) {
          ref.current = maskedRef ? maskedRef.inputElement : null;
        }
      }}
      mask={['+', '6', '0', '1', /\d/,'-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,/\d/]}
      placeholderChar={'\u2000'}
    />
  );
});

// NRIC Number Mask Component
const NRICNoMask = React.forwardRef(function NRICNoMask(props, ref) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(maskedRef) => {
        if (typeof ref === 'function') {
          ref(maskedRef ? maskedRef.inputElement : null);
        } else if (ref) {
          ref.current = maskedRef ? maskedRef.inputElement : null;
        }
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
});

export { MobileNumberMask, PhoneNumberMask, NRICNoMask };
