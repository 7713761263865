import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Typography, Box, Paper, CircularProgress, Alert, Grid, TextField, Button, MenuItem, Select, InputLabel, FormControl, InputAdornment
} from '@mui/material';

const EditFinanceLoan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [financeLoan, setFinanceLoan] = useState({
    salePrice: '',
    deposit: '',
    tenure: '',
    financingAmount: '',
    totalLoanPayment: '',
    monthlyInstalmentAmount: '',
  });
  const [bikeModels, setBikeModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [filteredColors, setFilteredColors] = useState([]);
  const [engines, setEngines] = useState([]);
  const [chassisNumbers, setChassisNumbers] = useState([]);
  const [filteredChassisNumbers, setFilteredChassisNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const tenureOptions = [6, 12, 18, 24, 30, 36, 42, 48, 54, 60];

  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBikeModels = async () => {
      try {
        const response = await axios.get(`${apiURL}/bikemodels`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setBikeModels(response.data);
      } catch (error) {
        console.error('Error fetching bike models:', error);
      }
    };

    const fetchFinanceData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const data = response.data;

        // Recalculate the finance data based on the fetched data
        const salePrice = parseFloat(data.salePrice) || 0;
        const deposit = parseFloat(data.deposit) || 0;
        const tenure = parseInt(data.tenure, 10) || 0;

        // Call calculateFinance to update the state with recalculated values
        const updatedFinanceLoan = calculateFinance(salePrice, deposit, tenure);

        setFinanceLoan({
          ...data,
          ...updatedFinanceLoan,
        });

      } catch (error) {
        console.error('Error fetching finance data:', error);
        setError('Failed to load finance data');
      } finally {
        setLoading(false);
      }
    };

    const fetchStockData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/stocks`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        const stockData = response.data;
        setEngines([...new Set(stockData.map(stock => stock.engineNumber))]); // Ensure unique engines
        setChassisNumbers(stockData);
        // Initialize filtered chassis numbers based on the default engine in financeLoan
        const initialFilteredChassis = stockData
          .filter(stock => stock.engineNumber === financeLoan.engine)
          .map(stock => stock.chassisNumber);
        setFilteredChassisNumbers(initialFilteredChassis);
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };

    fetchBikeModels();
    fetchFinanceData();
    fetchStockData();
  }, [id, apiURL, financeLoan.engine]);

  useEffect(() => {
    // Update filtered chassis numbers whenever the selected engine changes
    if (financeLoan.engine) {
      const filteredChassis = chassisNumbers
        .filter(stock => stock.engineNumber === financeLoan.engine)
        .map(stock => stock.chassisNumber);
      setFilteredChassisNumbers(filteredChassis);
    }
  }, [financeLoan.engine, chassisNumbers]);

  useEffect(() => {
    if (bikeModels.length) {
      const filteredModels = bikeModels.filter(
        (model) => model.manufacturing === financeLoan.model?.manufacturing
      );
      setFilteredModels(filteredModels);

      const filteredVariants = filteredModels
        .filter((model) => model.model === financeLoan.model?.model)
        .map((model) => model.variants);
      setFilteredVariants(filteredVariants);

      const filteredColors = filteredModels
        .filter((model) => model.model === financeLoan.model?.model && model.variants === financeLoan.model?.variants)
        .map((model) => model.color);
      setFilteredColors(filteredColors);
    }
  }, [bikeModels, financeLoan.model?.manufacturing, financeLoan.model?.model, financeLoan.model?.variants]);

  const calculateFinance = (salePrice, deposit, tenure) => {
    if (!salePrice || !deposit || !tenure) return {};

    const financeAmount = salePrice - deposit;
    const interestRates = {
      6: 0.05, 12: 0.10, 18: 0.15, 24: 0.20, 30: 0.25, 36: 0.30, 42: 0.35, 48: 0.40, 54: 0.45, 60: 0.50,
    };
    const interestRate = interestRates[tenure] || 0.50;
    const interestValue = financeAmount * interestRate;
    const totalAmount = financeAmount + interestValue;
    const monthlyInstallmentRaw = totalAmount / tenure;

    // Adjusting monthly installment calculation
    const monthlyInstallment = Math.ceil(monthlyInstallmentRaw);

    return {
      financingAmount: financeAmount.toFixed(2),
      totalLoanPayment: totalAmount.toFixed(2),
      monthlyInstalmentAmount: monthlyInstallment,
    };
};


  const handleInputChange = (field, value) => {
    setFinanceLoan((prevState) => {
      const newState = { ...prevState };

      if (field === 'salePrice' || field === 'deposit' || field === 'tenure') {
        newState[field] = value;
      }

      if (field === 'model.manufacturing') {
        const filteredModels = bikeModels.filter(
          (model) => model.manufacturing === value
        );
        setFilteredModels(filteredModels);

        newState.model = {
          manufacturing: value,
          model: '',
          variants: '',
          color: ''
        };
        setFilteredVariants([]);
        setFilteredColors([]);
      }

      if (field === 'model.model') {
        const filteredVariants = filteredModels
          .filter((model) => model.model === value)
          .map((model) => model.variants);
        setFilteredVariants(filteredVariants);

        newState.model = {
          ...newState.model,
          model: value,
          variants: '',
          color: ''
        };
        setFilteredColors([]);
      }

      if (field === 'model.variants') {
        const filteredColors = filteredModels
          .filter((model) => model.model === newState.model.model && model.variants === value)
          .map((model) => model.color);
        setFilteredColors(filteredColors);

        newState.model = {
          ...newState.model,
          variants: value,
          color: ''
        };
      }

      if (field === 'model.color') {
        newState.model = {
          ...newState.model,
          color: value
        };
      }

      // Parse the updated salePrice, deposit, or tenure fields to ensure they are correctly formatted for calculations
      const salePrice = parseFloat(newState.salePrice) || 0;
      const deposit = parseFloat(newState.deposit) || 0;
      const tenure = parseInt(newState.tenure, 10) || 0;

      // Recalculate finance based on updated fields
      const updatedFinance = calculateFinance(salePrice, deposit, tenure);

      // Update the state with the new model data and recalculated finance values
      return {
        ...newState,
        ...updatedFinance
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      const storedToken = localStorage.getItem('token');
      const payload = {
        ...financeLoan,
        model: {
          manufacturing: financeLoan.model.manufacturing,
          model: financeLoan.model.model,
          variants: financeLoan.model.variants,
          color: financeLoan.model.color
        }
      };

      // Remove any flat model keys if they exist
      delete payload["model.manufacturing"];
      delete payload["model.model"];
      delete payload["model.variants"];
      delete payload["model.color"];

      await axios.put(`${apiURL}/financeloans/${id}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      navigate(`/salesloans`);
    } catch (error) {
      console.error('Error updating finance data:', error);
      setError('Failed to update finance data');
    } finally {
      setSaving(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom>
          Edit Finance
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            {/* Input Fields */}
            <Grid item xs={12}>
              <TextField
                label="Product"
                name="product"
                select
                fullWidth
                value={financeLoan.product || ''}
                onChange={(e) => handleInputChange('product', e.target.value)}
                required
                margin="normal"
              >
                <MenuItem value="SHOP">SHOP</MenuItem>
                <MenuItem value="AEON">AEON</MenuItem>
                <MenuItem value="JCL">JCL</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Condition"
                name="condition"
                select
                fullWidth
                value={financeLoan.condition || ''}
                onChange={(e) => handleInputChange('condition', e.target.value)}
                required
                margin="normal">
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="Used">Used</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Manufacturing</InputLabel>
                <Select
                  name="model.manufacturing"
                  value={financeLoan.model?.manufacturing || ''}
                  onChange={(e) => handleInputChange('model.manufacturing', e.target.value)}>
                  {bikeModels.map((model) => (
                    <MenuItem key={model.id} value={model.manufacturing}>
                      {model.manufacturing}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Model</InputLabel>
                <Select
                  name="model.model"
                  value={financeLoan.model?.model || ''}
                  onChange={(e) => handleInputChange('model.model', e.target.value)}>
                  {filteredModels.map((model) => (
                    <MenuItem key={model.id} value={model.model}>
                      {model.model}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Variants</InputLabel>
                <Select
                  name="model.variants"
                  value={financeLoan.model?.variants || ''}
                  onChange={(e) => handleInputChange('model.variants', e.target.value)}>
                  {filteredVariants.map((variant) => (
                    <MenuItem key={variant} value={variant}>
                      {variant}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Color</InputLabel>
                <Select
                  name="model.color"
                  value={financeLoan.model?.color || ''}
                  onChange={(e) => handleInputChange('model.color', e.target.value)}>
                  {filteredColors.map((color) => (
                    <MenuItem key={color} value={color}>
                      {color}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Sale Price"
                type="number"
                fullWidth
                value={financeLoan.salePrice || ''}
                onChange={(e) => handleInputChange('salePrice', e.target.value)}
                required
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Deposit"
                type="number"
                fullWidth
                value={financeLoan.deposit || ''}
                onChange={(e) => handleInputChange('deposit', e.target.value)}
                required
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tenure (months)</InputLabel>
                <Select
                  name="tenure"
                  value={financeLoan.tenure || ''}
                  onChange={(e) => handleInputChange('tenure', parseInt(e.target.value, 10))}
                  required
                >
                  {tenureOptions.map((tenure) => (
                    <MenuItem key={tenure} value={tenure}>
                      {tenure} months
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Monthly Instalment Amount"
                type="number"
                fullWidth
                value={financeLoan.monthlyInstalmentAmount || ''}
                disabled
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Financing Amount"
                type="number"
                fullWidth
                value={financeLoan.financingAmount || ''}
                disabled
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Total Loan Payment"
                type="number"
                fullWidth
                value={financeLoan.totalLoanPayment || ''}
                disabled
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary" disabled={saving}>
              {saving ? 'Saving...' : 'Save'}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/salesloans')}
              sx={{ ml: 2 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default EditFinanceLoan;
