import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PaymentTermsDialog from './PaymentTermsDialog'; // Ensure the path is correct
import UpdatePaymentTerms from './UpdatePaymentTerms'; // Ensure the path is correct

const PaymentDataEid = () => {
  const [paymentTerms, setPaymentTerms] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null); // State for selected term
  const [updateOpen, setUpdateOpen] = useState(false); // State for dialog
  const apiURL = process.env.REACT_APP_API_URL;
  const { id } = useParams(); // Get the id from URL

  useEffect(() => {
    const fetchPaymentTerms = async () => {
      setLoading(true);
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/paymenterms/${id}`,{
          method:'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        setPaymentTerms(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentTerms();
  }, [id]);

  const handleUpdateOpen = (term) => {
    setSelectedTerm(term);
    setUpdateOpen(true);
  };

  const handleUpdateClose = () => {
    setUpdateOpen(false);
    setSelectedTerm(null);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <PaymentTermsDialog
        data={paymentTerms}
        onUpdateClick={handleUpdateOpen} // Pass the update handler to dialog
      />
      {selectedTerm && (
        <UpdatePaymentTerms
          open={updateOpen}
          handleClose={handleUpdateClose}
          term={selectedTerm}
        />
      )}
    </div>
  );
};

export default PaymentDataEid;
