import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode';
import './navbar.scss';

const Navbar = () => {
  const [user, setUser] = useState({ username: '', role: '' });
  const apiURL = process.env.REACT_APP_API_URL ;

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const storedToken = localStorage.getItem('token');
        
        const response = await fetch(`${apiURL}/auth/users/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setUser({ username: data.username, role: data.role });
        console.log('user data:', data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      console.log('Decoded Token:', decodedToken);

      const userId = decodedToken.user.id;
      console.log('User ID:', userId);

      if (userId) {
        fetchUserData(userId);
      } else {
        console.error('User ID is undefined in the token.');
      }
    }
  }, [apiURL]);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          {/* <input type="text" placeholder="search..." />
          <SearchOutlinedIcon className="icon" /> */}
        </div>
        <div className="items">
          <div className="item">
            <span>{user.username}/</span><br></br>
            <span> {user.role}</span>
          </div>
          {/* <div className="item">
            <img
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
