import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import EditFinanceLoan from '../../components/table/loan/EditFinanceLoan';

const EditFinance = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
         <EditFinanceLoan />
      </div>
    </div>
  );
};

export default EditFinance;

