import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Menu, MenuItem, Button, Box, Typography } from '@mui/material';
import axios from 'axios';
import styles from './ViewCustomerLoan.scss'; // Assuming you have your own stylesheet
import { makeStyles } from '@mui/styles';
import AddReferenceDialog from './AddReferenceDialog';
import CustomerCashTable from './customerCashTable';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#037b7d', // Replace with your desired color
    color: '#fff',
    zIndex: 10, // Set z-index here
    '&:hover': {
      backgroundColor: '#2c9294', // Replace with your desired hover color
    },
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'flex-start', // Align tabs to the left
  },
});

const ViewCustomerCash = () => {
  const [tabValue, setTabValue] = useState(0);
  const [customerLoans, setCustomerLoans] = useState([]);
  const [employments, setEmployments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [newReference, setNewReference] = useState({
    customerId: '',
    name: '',
    nricNo: '',
    contactNumber: '',
    relationship: ''
  });
  const [activeRow, setActiveRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const apiURL = process.env.REACT_APP_API_URL;
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpenDialog = (customerId) => {
    setNewReference({ ...newReference, customerId });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActiveRow(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReference({ ...newReference, [name]: value });
  };

  const handleFormSubmit = async () => {
    try {
      await axios.post(`${apiURL}/referencesloans`, newReference);
      // Optionally fetch references again to update the list
      setOpenDialog(false);
      setActiveRow(null);
    } catch (error) {
      console.error('Error creating reference:', error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddReferenceClick = (customerId) => {
    setNewReference({ ...newReference, customerId });
    setAnchorEl(null);
    setOpenDialog(true);
  };

  // useEffect(() => {
  //   const fetchCustomerLoans = async () => {
  //     try {
  //       const response = await axios.get(`${apiURL}/customercashs`);
  //       setCustomerLoans(response.data);
  //     } catch (error) {
  //       console.error('Error fetching customer loans:', error);
  //     }
  //   };

  //   const fetchEmployments = async () => {
  //     try {
  //       const response = await axios.get(`${apiURL}/employmentcashs`);
  //       setEmployments(response.data);
  //     } catch (error) {
  //       console.error('Error fetching employments:', error);
  //     }
  //   };

  //   const fetchAttachments = async () => {
  //     try {
  //       const response = await axios.get(`${apiURL}/attachmentcashs`);
  //       const flattenedAttachments = response.data.map(item => ({
  //         ...item,
  //         nricFront: item.nric.front,
  //         nricBack: item.nric.back,
  //         licenceFront: item.licence.front,
  //         licenceBack: item.licence.back,
  //       }));
  //       setAttachments(flattenedAttachments);
  //     } catch (error) {
  //       console.error('Error fetching attachments:', error);
  //     }
  //   };

  //   fetchCustomerLoans();
  //   fetchEmployments();
  //   fetchAttachments();
  // }, []);
  useEffect(() => {
    const fetchCustomerLoans = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${apiURL}/customercashs`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        setCustomerLoans(response.data);
      } catch (error) {
        console.error('Error fetching customer loans:', error);
      }
    };
  
    const fetchEmployments = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${apiURL}/employmentcashs`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        setEmployments(response.data);
      } catch (error) {
        console.error('Error fetching employments:', error);
      }
    };
  
    const fetchAttachments = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${apiURL}/attachmentcashs`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const flattenedAttachments = response.data.map(item => ({
          ...item,
          nricFront: item.nric.front,
          nricBack: item.nric.back,
          licenceFront: item.licence.front,
          licenceBack: item.licence.back,
        }));
        setAttachments(flattenedAttachments);
      } catch (error) {
        console.error('Error fetching attachments:', error);
      }
    };
  
    fetchCustomerLoans();
    fetchEmployments();
    fetchAttachments();
  }, [apiURL]);
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const customerLoanColumns = [
    { field: 'cashType', headerName: 'Product' },
    { field: 'name', headerName: 'Name' },
    { field: 'nricNo', headerName: 'NRIC No' },
    { field: 'mobileNumber', headerName: 'Mobile' },
    { field: 'email', headerName: 'Email' },
    { field: 'gender', headerName: 'Gender' },
    { field: 'maritalStatus', headerName: 'Marital Status' },
    { field: 'race', headerName: 'Race' },
    { field: 'address', headerName: 'Address' },
    { field: 'updatedAt', headerName: 'Updated At' }
  ];

  const employmentColumns = [
    { field: 'occupation', headerName: 'Occupation' },
    { field: 'occupationType', headerName: 'Occupation Type' },
    { field: 'employmentLength', headerName: 'Employment Length' },
    { field: 'employmentSegment', headerName: 'Employment Segment' },
    { field: 'employerName', headerName: 'Employer Name' },
    { field: 'employerPhoneNumber', headerName: 'Employer Phone' },
    { field: 'supervisorName', headerName: 'Supervisor Name' },
    { field: 'supervisorPhoneNumber', headerName: 'Supervisor Phone' },
    { field: 'employerAddress', headerName: 'Employer Address' },
    { field: 'monthlyGrossSalary', headerName: 'Monthly Gross Salary' },
    { field: 'monthlyNetSalary', headerName: 'Monthly Net Salary' },
    { field: 'salaryCreditingBank', headerName: 'Salary Crediting Bank' },
    { field: 'salaryCreditingAccountNumber', headerName: 'Salary Crediting Account' },
    { field: 'salaryCreditingDay', headerName: 'Salary Crediting Day' },
    { field: 'epf', headerName: 'EPF' },
  ];

  const attachmentColumns = [
    { 
      field: 'nricFront', 
      headerName: 'NRIC Front',
      render: (value) => (
        <a href={value} download> 
        
        <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}>NRIC Front</Button>  </a>
      )
    },
    { 
      field: 'nricBack', 
      headerName: 'NRIC Back',
      render: (value) => (
        <a href={value} download> 
        <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}>NRIC Back</Button> </a>
      )
    },
    { 
      field: 'licenceFront', 
      headerName: 'LICENCE Front',
      render: (value) => (
        <a href={value} download> 
        <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}>LICENCE Front</Button></a>
      )
    },
    { 
      field: 'licenceBack', 
      headerName: 'LICENCE Back',
      render: (value) => (
        <a href={value} download> 
          <Button variant="contained"  style={{backgroundColor:'rgb(44, 146, 148)',textTransform:'capitalize'}}>LICENCE Back</Button></a>
      )
    }
  ];

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
       // Margin-bottom for spacing
      >
        <Typography variant="h4" style={{marginLeft:'10px'}}>Cash Customer </Typography>
        <Button
          variant="contained"
          color="primary"
          href="/customers/cashs/addcustomercash"
          style={{ backgroundColor: '#2c9294', marginRight:'10px' }}
        >
          Add Cash Customer
        </Button>
      </Box>
      
      <div className={classes.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Customer Loans" />
          <Tab label="Employments" />
          <Tab label="Attachments" />
        </Tabs>
      </div>
      <div className="table-container">
        {tabValue === 0 && (
          <CustomerCashTable
            data={customerLoans}
            columns={customerLoanColumns}
            isCustomerLoanTable={true}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onMenuClick={handleMenuClick}
            onMenuClose={handleMenuClose}
            onAddReferenceClick={handleAddReferenceClick}
            anchorEl={anchorEl}
          />
        )}
        {tabValue === 1 && (
          <CustomerCashTable
            data={employments}
            columns={employmentColumns}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {tabValue === 2 && (
          <CustomerCashTable
            data={attachments}
            columns={attachmentColumns}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>

      <AddReferenceDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onChange={handleInputChange}
        onSubmit={handleFormSubmit}
        newReference={newReference}
      />
    </div>
  );
};

export default ViewCustomerCash;
