import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import EditEmploymentLoan from '../../components/table/salesloandata/EditEmploymentLoan';


const SalesEditEmployment = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />

         <EditEmploymentLoan />
      </div>
    </div>
  );
};

export default SalesEditEmployment;

