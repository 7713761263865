import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const UpdateStock = () => {
  const { id } = useParams();  
  const navigate = useNavigate();
  const [bikeModel, setBikeModel] = useState({
    supplier: '',
    manufacturing: '',
    model: '',
    variants: '',
    color: '',
    engineNumber: '',
    chassisNumber: '',
    condition: '',
    stockInDate: '',
    stockOutDate: '',
    cc: 0,
    vendorPrice: 0,
    sellingPrice: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [redirectAfterClose, setRedirectAfterClose] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBikeModel = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        if (!storedToken) {
          throw new Error('No token found, authorization required');
        }

        const response = await axios.get(`${apiURL}/stocks/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setBikeModel(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBikeModel();
  }, [id]);

  const handleInputChange = (field, value) => {
    setBikeModel({ ...bikeModel, [field]: value });
  };

  const handleSaveBikeModel = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        throw new Error('No token found, authorization required');
      }

      const response = await axios.put(`${apiURL}/stocks/${id}`, bikeModel, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log('Bike model updated:', response.data);
      setSnackbarMessage('Update successful!');
      setOpenSnackbar(true);
      setRedirectAfterClose(true); // Set flag to redirect after Snackbar closes
    } catch (error) {
      console.error('Error updating bike model:', error);
      setError('Failed to update bike model. Please try again.');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    if (redirectAfterClose) {
      navigate('/stocks');
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ px: 2 }}
    >
      <Box
        width="100%"
        maxWidth="800px"
        p={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
      >
        <Typography variant="h4" align="center" gutterBottom>
          Update Stock
        </Typography>
        <form>
          <TextField
            label="Supplier"
            value={bikeModel.supplier}
            onChange={(e) => handleInputChange('supplier', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Manufacturing"
            value={bikeModel.manufacturing}
            onChange={(e) => handleInputChange('manufacturing', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Model"
            value={bikeModel.model}
            onChange={(e) => handleInputChange('model', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Variants"
            value={bikeModel.variants}
            onChange={(e) => handleInputChange('variants', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Color"
            value={bikeModel.color}
            onChange={(e) => handleInputChange('color', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Engine Number"
            value={bikeModel.engineNumber}
            onChange={(e) => handleInputChange('engineNumber', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Chassis Number"
            value={bikeModel.chassisNumber}
            onChange={(e) => handleInputChange('chassisNumber', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Condition"
            value={bikeModel.condition}
            onChange={(e) => handleInputChange('condition', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Stock In Date"
            type="date"
            value={bikeModel.stockInDate}
            onChange={(e) => handleInputChange('stockInDate', e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Stock Out Date"
            type="date"
            value={bikeModel.stockOutDate}
            onChange={(e) => handleInputChange('stockOutDate', e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="CC"
            type="number"
            value={bikeModel.cc}
            onChange={(e) => handleInputChange('cc', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Vendor Price"
            type="number"
            value={bikeModel.vendorPrice}
            onChange={(e) => handleInputChange('vendorPrice', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Selling Price"
            type="number"
            value={bikeModel.sellingPrice}
            onChange={(e) => handleInputChange('sellingPrice', e.target.value)}
            fullWidth
            margin="normal"
          />
        </form>
        <Box display="flex" justifyContent="center" mt={3}>
          <Button onClick={handleSaveBikeModel} variant="contained" style={{ backgroundColor: '#2c9294' }}>
            Save Stock
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: 0 }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UpdateStock;


