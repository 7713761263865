import React, { useEffect, useState } from 'react';
import { Table, TableContainer, Paper, TablePagination, TableHead, TableRow, TableCell, TableBody, IconButton, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

const EmploymentLoan = () => {
  const [employments, setEmployments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const apiURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployments = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/employmentloans/my-emp`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        const transformedEmployments = response.data.map(item => ({
          ...item,
          customerName: item.customerId ? item.customerId.name : 'Unknown',
          monthlyGrossSalary: parseFloat(item.monthlyGrossSalary).toFixed(2),
          monthlyNetSalary: parseFloat(item.monthlyNetSalary).toFixed(2),
        }));
        setEmployments(transformedEmployments);
      } catch (error) {
        console.error('Error fetching employments:', error);
      }
    };

    fetchEmployments();
  }, [apiURL]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleClickOpenEditDialog = () => {
    if (selectedRow && selectedRow._id) { // Assuming the row has an `_id` field
      navigate(`/salesloans/employments/${selectedRow._id}`);

      console.log('Edit employment dialog opened for:', selectedRow);
      handleMenuClose();
    } else {
      console.error('No row selected or row does not have an id');
    }
  };

  const employmentColumns = [
    { field: 'customerName', headerName: 'Customer ' },
    { field: 'occupation', headerName: 'Occupation' },
    { field: 'occupationType', headerName: 'Occup. Type' },
    { field: 'employmentLength', headerName: 'Employment Length' },
    { field: 'employmentSegment', headerName: 'Employment Segment' },
    { field: 'employerName', headerName: 'Employer Name' },
    { field: 'employerPhoneNumber', headerName: 'Employer Phone' },
    { field: 'supervisorName', headerName: 'Supervisor Name' },
    { field: 'supervisorPhoneNumber', headerName: 'Supervisor Phone' },
    { field: 'employerAddress', headerName: 'Employer Address' },
    { field: 'monthlyGrossSalary', headerName: 'Gross Salary' },
    { field: 'monthlyNetSalary', headerName: 'Net Salary' },
    { field: 'salaryCreditingBank', headerName: 'Salary Bank' },
    { field: 'salaryCreditingAccountNumber', headerName: 'Account No.' },
    { field: 'salaryCreditingDay', headerName: 'Salary Date' },
  ];

  const renderTableCell = (row, column) => {
    const fieldParts = column.field.split('.');
    let value = row;

    for (const part of fieldParts) {
      value = value[part];
    }

    return column.render ? column.render(value) : value;
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {employmentColumns.map((column) => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employments.length > 0 ? employments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                {employmentColumns.map((column) => (
                  <TableCell key={column.field}>
                    {renderTableCell(row, column)}
                  </TableCell>
                ))}
                <TableCell>
                  <IconButton onClick={(e) => handleMenuOpen(e, row)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedRow === row}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleClickOpenEditDialog}>Edit</MenuItem>
                    
                  </Menu>
                </TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={employmentColumns.length + 1} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={employments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default EmploymentLoan;
