import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Input
} from '@mui/material';
import axios from 'axios';
import ReactFileReader from 'react-file-reader';
import * as XLSX from 'xlsx'; // Import xlsx to read Excel files

const AddScoreDialog = ({ open, onClose, onSubmit, newReference }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [scores, setScores] = useState([{ title: 'GENDER', score: '1', scoreOutOf: '5' },
    { title: 'RACE', score: '1', scoreOutOf: '5' },
    { title: 'AGE', score: '1', scoreOutOf: '5' },
    { title: 'ADDRESS', score: '1', scoreOutOf: '5' },
    { title: 'MARITAL STATUS', score: '1', scoreOutOf: '5' },
    { title: 'Age', score: '1', scoreOutOf: '5' },
    { title: 'REFERENCE', score: '1', scoreOutOf: '5' },
    { title: 'OCCUPATION', score: '1', scoreOutOf: '5' },
    { title: 'COMMITMENT', score: '1', scoreOutOf: '5' },
    { title: 'SALARY', score: '1', scoreOutOf: '5' },
    { title: 'BANK STATEMENT', score: '1', scoreOutOf: '5' },
    { title: 'CTOS', score: '1', scoreOutOf: '5' },
    { title: 'GUARANTOR', score: '1', scoreOutOf: '10' },
    { title: 'UTILITIES BILL', score: '1', scoreOutOf: '10' },
    { title: 'EPF STATEMENT', score: '1', scoreOutOf: '10' },
    { title: 'INCOME STAEMENT', score: '1', scoreOutOf: '10' }
  
  ]);
  const [totalScore, setTotalScore] = useState(0);
  const [totalScoreOutOf, setTotalScoreOutOf] = useState(0);
  const [existingScores, setExistingScores] = useState([]);
  const [existingTotalScore, setExistingTotalScore] = useState(0);
  const [existingTotalScoreOutOf, setExistingTotalScoreOutOf] = useState(0);
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if ((tabIndex === 1 || tabIndex === 2) && open) {
      fetchExistingScores();
    }
  }, [tabIndex, open]);

  const handleClose = () => {
    onClose();
    setScores([{ title: '', score: '5', scoreOutOf: '10' }

    ]);
    setExistingScores([]);
    setExistingTotalScore(0);
    setExistingTotalScoreOutOf(0);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedScores = [...scores];
    updatedScores[index] = { ...updatedScores[index], [name]: value };
    setScores(updatedScores);
    calculateTotals(updatedScores); // Recalculate totals on input change
  };

  const handleExistingInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedScores = [...existingScores];
    updatedScores[index] = { ...updatedScores[index], [name]: value };
    setExistingScores(updatedScores);
    calculateExistingTotals(updatedScores); // Recalculate existing totals on input change
  };

  const addScoreField = () => {
    setScores([...scores, { title: '', score: '0', scoreOutOf: '10' }]);
  };

  const removeScoreField = (index) => {
    const updatedScores = [...scores];
    updatedScores.splice(index, 1);
    setScores(updatedScores);
    calculateTotals(updatedScores); // Recalculate totals after removing score
  };

  const calculateTotals = (scoresArray) => {
    let totalScore = 0;
    let totalScoreOutOf = 0;
    scoresArray.forEach(score => {
      if (!isNaN(score.score)) {
        totalScore += Number(score.score);
      }
      if (!isNaN(score.scoreOutOf)) {
        totalScoreOutOf += Number(score.scoreOutOf);
      }
    });
    setTotalScore(totalScore);
    setTotalScoreOutOf(totalScoreOutOf);
  };

  const calculateExistingTotals = (existingScoresArray) => {
    let totalScore = 0;
    let totalScoreOutOf = 0;
    existingScoresArray.forEach(score => {
      if (!isNaN(score.score)) {
        totalScore += Number(score.score);
      }
      if (!isNaN(score.scoreOutOf)) {
        totalScoreOutOf += Number(score.scoreOutOf);
      }
    });
    setExistingTotalScore(totalScore);
    setExistingTotalScoreOutOf(totalScoreOutOf);
  };

  useEffect(() => {
    calculateTotals(scores);
  }, [scores]);

  useEffect(() => {
    calculateExistingTotals(existingScores);
  }, [existingScores]);

  // const handleSubmit = async () => {
  //   try {
  //     const scoresToSend = scores.map(score => ({
  //       title: score.title,
  //       score: Number(score.score),
  //       scoreOutOf: Number(score.scoreOutOf)
  //     }));

  //     const response = await axios.post(`${apiURL}/scores`, {
  //       customerId: newReference.customerId,
  //       scores: scoresToSend
  //     });
  //     console.log('Scores created:', response.data);
  //     onSubmit(); // Trigger the onSubmit callback after successful submit
  //     setTabIndex(1); // Switch to the View tab
  //     fetchExistingScores(); // Refresh existing scores
  //   } catch (error) {
  //     console.error('Error creating scores:', error);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      const scoresToSend = scores.map(score => ({
        title: score.title,
        score: Number(score.score),
        scoreOutOf: Number(score.scoreOutOf)
      }));
      const storedToken = localStorage.getItem('token');
      const response = await axios.post(`${apiURL}/scores`, {
        attachmentId: newReference.attachmentId, // Ensure this is included
        scores: scoresToSend
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      });
  
      console.log('Scores created:', response.data);
      onSubmit(); // Trigger the onSubmit callback after successful submit
      setTabIndex(1); // Switch to the View tab
      fetchExistingScores(); // Refresh existing scores
    } catch (error) {
      console.error('Error creating scores:', error);
    }
  };
  

  const handleUpdate = async () => {
    try {
      const scoresToUpdate = existingScores.map(score => ({
        title: score.title,
        score: Number(score.score),
        scoreOutOf: Number(score.scoreOutOf)
      }));
      const storedToken = localStorage.getItem('token');
      const response = await axios.put(`${apiURL}/scores/${newReference.attachmentId}`, {
        attachmentId: newReference.attachmentId,
        scores: scoresToUpdate
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      });
  
      console.log('Scores updated:', response.data);
      onSubmit(); // Trigger the onSubmit callback after successful update
    } catch (error) {
      console.error('Error updating scores:', error);
    }
  };
  
  const fetchExistingScores = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.get(`${apiURL}/scores/${newReference.attachmentId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      });
      setExistingScores(response.data.scores);
      setExistingTotalScore(response.data.totalScore);
      setExistingTotalScoreOutOf(response.data.totalOutOfScore);
    } catch (error) {
      console.error('Error fetching existing scores:', error);
    }
  };
  

  const handleFileUpload = async (files) => {
    try {
      const file = files[0]; // Assuming single file upload
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
        const sheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        
        // Assuming your Excel has columns: Title, Score, Score Out Of
        const scoresFromExcel = excelData.slice(1).map(row => ({
          title: row[0], // Adjust column index based on your Excel structure
          score: row[1] || '0',
          scoreOutOf: row[2] || '10'
        }));

        setScores(scoresFromExcel);
        calculateTotals(scoresFromExcel); // Recalculate totals based on imported data
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Error uploading Excel file:', error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Manage Scores for {' '}
        <span style={{ color: 'blue', fontSize: '17px' }}>{newReference.name}</span>
      </DialogTitle>
      <DialogContent>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Score Tabs">
          <Tab label="Create" />
          <Tab label="View" />
          <Tab label="Update" />
        </Tabs>
        {tabIndex === 0 && (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Score Out Of</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scores.map((score, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        name="title"
                        value={score.title}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="score"
                        type="number"
                        value={score.score}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="scoreOutOf"
                        type="number"
                        value={score.scoreOutOf}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </TableCell>
                    <TableCell>
                      {index > 0 && (
                        <Button onClick={() => removeScoreField(index)}>Remove</Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button onClick={addScoreField}>Add Score</Button>
            <Typography variant="body1" style={{ marginTop: '10px' }}>
              Total Score: {totalScore} / {totalScoreOutOf}
            </Typography>
          </div>
        )}
        {tabIndex === 1 && (
          <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            {existingScores.length > 0 ? (
              existingScores.map((score, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      name="title"
                      label="Title"
                      value={score.title}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="score"
                      label="Score"
                      type="number"
                      value={score.score}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="scoreOutOf"
                      label="Score Out Of"
                      type="number"
                      value={score.scoreOutOf}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                No existing scores available.
              </Typography>
            )}
            <Box mt={2}>
              <Typography variant="body1">
                Total Score: {existingTotalScore} / {existingTotalScoreOutOf}
              </Typography>
            </Box>
          </div>
        )}
        {tabIndex === 2 && (
          <div>
            {existingScores.length > 0 ? (
              existingScores.map((score, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      name="title"
                      label="Title"
                      value={score.title}
                      onChange={(e) => handleExistingInputChange(index, e)}
                      fullWidth
                      margin="normal"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="score"
                      label="Score"
                      type="number"
                      value={score.score}
                      onChange={(e) => handleExistingInputChange(index, e)}
                      fullWidth
                      margin="normal"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="scoreOutOf"
                      label="Score Out Of"
                      type="number"
                      value={score.scoreOutOf}
                      onChange={(e) => handleExistingInputChange(index, e)}
                      fullWidth
                      margin="normal"
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                No existing scores available.
              </Typography>
            )}
            <Button onClick={handleUpdate}>Update Scores</Button>
            <Box mt={2}>
              <Typography variant="body1">
                Total Score: {existingTotalScore} / {existingTotalScoreOutOf}
              </Typography>
            </Box>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {tabIndex === 0 && (
          <Button onClick={handleSubmit}>Create Scores</Button>
        )}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>

      {/* File Upload Section */}
      <Box mt={2}>
        <ReactFileReader fileTypes={[".xls", ".xlsx"]} handleFiles={handleFileUpload}>
          <Button>Upload Excel</Button>
        </ReactFileReader>
      </Box>
    </Dialog>
  );
};

AddScoreDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  newReference: PropTypes.object.isRequired,
};

export default AddScoreDialog;
