import React, { useEffect, useState } from 'react';
import { Doughnut as ChartDoughnut, Bar as ChartBar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale } from 'chart.js';
import './CustomDoughnutChart.scss';

ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale);

const Doughnut = () => {
  const [statusData, setStatusData] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);
  const [loanAmountData, setLoanAmountData] = useState(null); // New state for loan amounts
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');

      try {
        const statusResponse = await fetch(`${apiURL}/financeloans/total-status`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const monthlyResponse = await fetch(`${apiURL}/financeloans/approved-monthly`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (statusResponse.ok && monthlyResponse.ok) {
          const statusData = await statusResponse.json();
          const monthlyData = await monthlyResponse.json();

          const { statusCounts, totalLoanAmountByStatus } = statusData;

          setStatusData({
            approvalData: [
              { name: 'Pending', value: statusCounts.pending },
              { name: 'Approved', value: statusCounts.approved },
              { name: 'Ready', value: statusCounts.ready },
              { name: 'Issue', value: statusCounts.issue },
              { name: 'Claim', value: statusCounts.claim },
            ],
          });

          setLoanAmountData([
            { name: 'Pending', value: totalLoanAmountByStatus.pending },
            { name: 'Approved', value: totalLoanAmountByStatus.approved },
            { name: 'Ready', value: totalLoanAmountByStatus.ready },
            { name: 'Issue', value: totalLoanAmountByStatus.issue },
            { name: 'Claim', value: totalLoanAmountByStatus.claim },
          ]);

          const paymentsByMonth = monthlyData.paymentsByMonth;
          const barData = Object.keys(paymentsByMonth).map(month => ({
            month,
            value: paymentsByMonth[month],
          }));

          setMonthlyData(barData);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  if (!statusData || !monthlyData || !loanAmountData) {
    return <div>Loading...</div>;
  }

  const CustomDoughnutChart = ({ title, data = [], colors }) => {
    const chartData = {
      labels: data.map(item => item.name),
      datasets: [
        {
          label: title,
          data: data.map(item => item.value),
          backgroundColor: colors,
          borderColor: colors.map(color => color),
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: title,
        },
      },
      cutout: '50%',
    };

    return (
      <div style={{ position: 'relative', width: '300px', height: '300px' }}>
        <ChartDoughnut data={chartData} options={options} />
      </div>
    );
  };

  const CustomBarChart = ({ title, data = [], colors }) => {
    const chartData = {
      labels: data.map(item => item.name || item.month),
      datasets: [
        {
          label: title,
          data: data.map(item => item.value),
          backgroundColor: colors,
          borderColor: colors.map(color => color),
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'none',
        },
        title: {
          display: true,
          text: title,
        },
      },
    };

    return (
      <div style={{ position: 'relative', width: '550px', height: '300px' }}>
        <ChartBar data={chartData} options={options} />
      </div>
    );
  };

  const COLORS = {
    approval: ['#FFBF00', '#40E0D0', '#DFFF00', '#6495ED', '#DE3163'],
    monthly: ['#6495ED'],
    loanAmounts: ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FF33F6'], // New color scheme for loan amounts
  };

  return (
    <div className="charts">
      <div className="doughnut-chart">
        <CustomDoughnutChart
          title="Monthly Status Submission"
          data={statusData.approvalData}
          colors={COLORS.approval}
        />
      </div>
      <div className="doughnut-chatbar">
        <CustomBarChart
          title="Monthly Payments"
          data={monthlyData}
          colors={COLORS.monthly}
        />
      </div>
      <div className="doughnut-chatbar">
        <CustomBarChart
          title="Loan Amounts by Status"
          data={loanAmountData}
          colors={COLORS.loanAmounts}
        />
      </div>
    </div>
  );
};

export default Doughnut;
