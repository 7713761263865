import React, { useEffect, useState } from 'react';
import {
  Table, TableContainer, Paper, TablePagination, TableHead, TableRow, TableCell, TableBody, IconButton, Menu, MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const FinanceLoan = () => {
  const [finances, setFinances] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const apiURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    axios.get(`${apiURL}/financeloans/my-finance`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storedToken}`,
      }
    })
      .then(response => {
        const transformedFinances = response.data.map((finance) => ({
          ...finance,
          salePrice: parseFloat(finance.salePrice).toFixed(2),
          deposit: parseFloat(finance.deposit).toFixed(2),
          financingAmount: parseFloat(finance.financingAmount).toFixed(2),
          monthlyInstalmentAmount: parseFloat(finance.monthlyInstalmentAmount).toFixed(2),
          totalLoanPayment: parseFloat(finance.totalLoanPayment).toFixed(2),
          customerName: finance.customerId ? finance.customerId.name : 'Unknown',
        }));
        setFinances(transformedFinances);
      })
      .catch(error => {
        console.error('Error fetching finance loans:', error);
      });
  }, [apiURL]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleClickOpenEditDialog = () => {
    if (selectedRow && selectedRow._id) { // Assuming the row has an `_id` field
      navigate(`/salesloans/finances/${selectedRow._id}`);

      console.log('Edit employment dialog opened for:', selectedRow);
      handleMenuClose();
    } else {
      console.error('No row selected or row does not have an id');
    }
  };

  const financeColumns = [
    { field: 'customerName', headerName: 'Customer' },
    { field: 'model.manufacturing', headerName: 'Manufacturing' },
    { field: 'model.model', headerName: 'Model' },
    { field: 'model.variants', headerName: 'Variants' },
    { field: 'product', headerName: 'Product' },
    { field: 'customerCommitment', headerName: 'Commitment' },
    { field: 'condition', headerName: 'Condition' },
    { field: 'salePrice', headerName: 'Sale Price' },
    { field: 'deposit', headerName: 'Deposit' },
    { field: 'tenure', headerName: 'Tenure' },
    { field: 'financingAmount', headerName: 'Financing Amount' },
    {
      field: 'monthlyInstalmentAmount',
      headerName: 'Monthly Amount',
      render: (value) => value ? parseFloat(value).toFixed(2) : '0.00',
    },
    { field: 'totalLoanPayment', headerName: 'Total Payment' }
  ];

  const renderTableCell = (row, column) => {
    const fieldParts = column.field.split('.');
    let value = row;

    for (const part of fieldParts) {
      value = value[part];
    }

    return column.render ? column.render(value) : value;
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {financeColumns.map((column) => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finances.length > 0 ? finances.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                {financeColumns.map((column) => (
                  <TableCell key={column.field}>
                    {renderTableCell(row, column)}
                  </TableCell>
                ))}
                <TableCell>
                  <IconButton onClick={(e) => handleMenuOpen(e, row)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleClickOpenEditDialog}>Edit finance</MenuItem>
                    
                  </Menu>
                </TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={financeColumns.length + 1} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={finances.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default FinanceLoan;
