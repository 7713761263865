import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button, Table, TableHead, TableBody, TableRow, TableCell, Typography, TablePagination } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const ScoreData = () => {
  const [scoreRecords, setScoreRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchScores = async () => {
      try {
        const response = await axios.get('http://192.168.0.139:5000/api/scores');
        setScoreRecords(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchScores();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <div>
      <Typography variant="h4">Score Management</Typography>
      <Button
        variant="contained"
        color="primary"
        href="/scores/addscore"
        style={{ marginBottom: '20px' }}
      >
        Add Score
      </Button>
      {scoreRecords.map((record, recordIndex) => (
        <Accordion key={recordIndex}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${recordIndex}-content`}
            id={`panel${recordIndex}-header`}
          >
            <Typography variant="h6">Record {recordIndex + 1}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Index</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Score Out Of</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.scores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.score}</TableCell>
                    <TableCell>{item.scoreOutOf}</TableCell>
                  </TableRow>
                ))}
                {rowsPerPage - Math.min(rowsPerPage, record.scores.length - page * rowsPerPage) > 0 && (
                  <TableRow style={{ height: 53 * (rowsPerPage - Math.min(rowsPerPage, record.scores.length - page * rowsPerPage)) }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={record.scores.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ScoreData;
