import './widget.scss';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Person2Icon from '@mui/icons-material/Person2';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const Widget = ({ type, amount, diff }) => {
  let data;
  let backgroundColor; // To store the background color based on the type

  switch (type) {
    case 'user':
      data = {
        title: 'Total Submission This Month',
        isMoney: false,
        link: 'See all Customers',
        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{ color: 'crimson', backgroundColor: 'rgba(255,0,0,0.2)' }}
          />
        ),
      };
      backgroundColor = '#e9f3ef'; // Light red background
      break;
    case 'balance':
      data = {
        title: 'Total Approved This Month',
        isMoney: false,
        link: 'View all deals',
        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{ color: 'green', backgroundColor: 'rgba(0,128,0,0.2)' }}
          />
        ),
      };
      backgroundColor = '#c9ebe8'; // Light green background
      break;
    case 'order':
      data = {
        title: 'Total Approved For This Month',
        isMoney: false,
        link: 'View all Applications',
        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{
              color: 'goldenrod',
              backgroundColor: 'rgba(218,165,32,0.2)',
            }}
          />
        ),
      };
      backgroundColor = '#b2e2de'; // Light yellow background
      break;
    case 'salesValue':
      data = {
        title: 'Total Sales Value This Month',
        isMoney: true,
        link: 'View all Sales',
        icon: (
          <AccountBalanceWalletIcon
            className="icon"
            style={{ color: 'blue', backgroundColor: 'rgba(0,0,255,0.2)' }}
          />
        ),
      };
      backgroundColor = '#a3dad6'; // Light cyan background
      break;
    case 'earning':
      data = {
        title: 'Total Loan This Month',
        isMoney: true,
        link: 'View all active Customers',
        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{ color: 'green', backgroundColor: 'rgba(0,128,0,0.2)' }}
          />
        ),
      };
      backgroundColor = '#84cbc5'; // Light green background
      break;


    case 'userytd':
      data = {
        title: 'Total Submission YTD',
        isMoney: false,
        link: 'See all Customers',
        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{ color: 'Plum', backgroundColor: 'rgba(255,0,0,0.2)' }}
          />
        ),
      };
      backgroundColor = '#e4e8fc'; // Lavender background
      break;
    case 'orderytd':
      data = {
        title: 'Total Approved YTD',
        isMoney: false,
        link: 'View all Applications',
        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{
              color: 'Orchid',
              backgroundColor: 'rgba(218,165,32,0.2)',
            }}
          />
        ),
      };
      backgroundColor = '#bdc8fc'; // Lavender background
      break;
    case 'earningytd':
      data = {
        title: 'Total Sales Value YTD',
        isMoney: true,
        link: 'View all active Customers',
        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{ color: 'MediumOrchid', backgroundColor: 'rgba(0,128,0,0.2)' }}
          />
        ),
      };
      backgroundColor = '#98abfc'; // Thistle background
      break;
    case 'balanceytd':
      data = {
        title: 'Total Loan YTD',
        isMoney: true,
        link: 'View all deals',
        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{ color: 'RebeccaPurple', backgroundColor: 'rgba(0,0,128,0.2)' }}
          />
        ),
      };
      backgroundColor = '#879cfb'; // Plum background
      break;
    case 'overdueAmount':
      data = {
        title: 'Total Over Due Customer',
        isMoney: false,

        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{ color: 'RebeccaPurple', backgroundColor: 'rgba(0,0,128,0.2)' }}
          />
        ),
      };
      backgroundColor = '#fef5f4'; // Plum background
      break;
    case 'overdueCustomer':
      data = {
        title: 'Total Over Due Amount',
        isMoney: true,

        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{ color: 'RebeccaPurple', backgroundColor: 'rgba(0,0,128,0.2)' }}
          />
        ),
      };
      backgroundColor = '#fee8e5'; // Plum background
      break;

    case 'PaidAmount':
      data = {
        title: 'This Month Collection Amount ',
        isMoney: true,

        icon: (
          <MonetizationOnIcon
            className="icon"
            style={{ color: 'RebeccaPurple', backgroundColor: 'rgba(0,0,128,0.2)' }}
          />
        ),
      };
      backgroundColor = '#fedbd6'; // Plum background
      break;
      case 'loanMonthlyRate':
        data = {
          title: 'This Month Loan Rate % ',
          isMoney: false,
  
          icon: (
            <MonetizationOnIcon
              className="icon"
              style={{ color: 'RebeccaPurple', backgroundColor: 'rgba(0,0,128,0.2)' }}
            />
          ),
        };
        backgroundColor = '#fdccc5'; // Plum background
        break;

    default:
      backgroundColor = '#FFFFFF'; // Default to white background if no type matches
      break;
  }

  return (
    <div className="widget" style={{ backgroundColor }}>
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney ? `RM ${amount}` : amount}
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
