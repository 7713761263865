import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Box
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { formatDate } from '../../utils/formatDate'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';

const BikeModelData = () => {
  const [bikeModels, setBikeModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBikeModels = async () => {
      try {
        const storedToken = localStorage.getItem('token');

        const response = await axios.get(`${apiURL}/bikemodels`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }

        });
        setBikeModels(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBikeModels();
  }, []);

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedModelId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedModelId(null);
  };

  const handleEdit = () => {
    if (selectedModelId) {
      console.log('Navigating to:', `/bikemodels/${selectedModelId}`);
      navigate(`/bikemodels/${selectedModelId}`);
    }
    handleMenuClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      // Margin-bottom for spacing
      >
        <Typography variant="h4" style={{ marginLeft: '10px' }}>Bike Models</Typography>
        <Button
          variant="contained"
          color="primary"
          href="/bikemodels/AddBikeModel"
          style={{ backgroundColor: '#2c9294', marginRight: '10px' }}
        >
          Add Bike Model
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Manufacturing</TableCell>
            <TableCell>Model</TableCell>
            <TableCell>Variants</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bikeModels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((model) => (
            <TableRow key={model._id}>
              <TableCell>{model._id}</TableCell>
              <TableCell>{model.manufacturing}</TableCell>
              <TableCell>{model.model}</TableCell>
              <TableCell>{model.variants}</TableCell>
              <TableCell>{model.color}</TableCell>
              <TableCell>{model.price}</TableCell>
              <TableCell>{formatDate(model.createdAt)}</TableCell>
              <TableCell>{formatDate(model.updatedAt)}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleMenuOpen(event, model._id)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl) && selectedModelId === model._id}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  {/* You can add more MenuItem components here for other actions */}
                </Menu>
              </TableCell>
            </TableRow>
          ))}
          {rowsPerPage - Math.min(rowsPerPage, bikeModels.length - page * rowsPerPage) > 0 && (
            <TableRow style={{ height: 0 * (rowsPerPage - Math.min(rowsPerPage, bikeModels.length - page * rowsPerPage)) }}>
              <TableCell colSpan={9} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={bikeModels.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default BikeModelData;
