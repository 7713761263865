import React, { useEffect, useState } from 'react';
import { TableContainer, Paper, TablePagination, IconButton, Menu, MenuItem, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import RenderTable from './RenderTable';
import AddReferenceDialog from '../AddReferenceDialog'; // Adjust the path as per your file structure
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ReferenceLoan = () => {
  const [references, setReferences] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [newReference, setNewReference] = useState({
    customerId: '', // Adjust according to your data structure
    name: '',
    nricNo: '',
    contactNumber: '',
    relationship: ''
  });
  const [anchorEl, setAnchorEl] = useState(null); // For menu
  const [customerLoans, setCustomerLoans] = useState([]);

  const apiURL = process.env.REACT_APP_API_URL;

  const fetchReferences = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.get(`${apiURL}/referencesloans`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      });
      const transformedReferences = response.data.map(item => ({
        ...item,
        customerName: item.customerId ? item.customerId.name : 'Unknown',
      }));
      setReferences(transformedReferences);
    } catch (error) {
      console.error('Error fetching references:', error);
    }
  };

  const fetchCustomerLoans = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.get(`${apiURL}/customerloans`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      });
      setCustomerLoans(response.data);
    } catch (error) {
      console.error('Error fetching customer loans:', error);
    }
  };

  useEffect(() => {
    fetchReferences();
    fetchCustomerLoans();
  }, [apiURL]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReference({ ...newReference, [name]: value });
  };

  const handleFormSubmit = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      await axios.post(`${apiURL}/referencesloans`,{
        method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
      }, newReference);
      setOpenDialog(false);
      fetchReferences(); // Fetch references again after adding new reference
    } catch (error) {
      console.error('Error creating reference:', error);
    }
  };


  const referenceColumns = [
    { field: 'customerName', headerName: 'Customer' },
    { field: 'name', headerName: 'Name' },
    { field: 'nricNo', headerName: 'NRIC No' },
    { field: 'contactNumber', headerName: 'Contact Number' },
    { field: 'relationship', headerName: 'Relationship' },
  ];

  return (
    <div>
     
        <TableContainer component={Paper} className="table">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead >
              <TableRow>
                {referenceColumns.map((col) => (
                  <TableCell key={col.field} >{col.headerName}</TableCell>
                ))}
           
              </TableRow>
            </TableHead>
            <TableBody>
              {references.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((reference) => (
                <TableRow key={reference._id} >
                  {referenceColumns.map((col) => (
                    <TableCell key={col.field} >
                      {col.render ? col.render(reference[col.field]) : reference[col.field]}
                    </TableCell>
                  ))}

                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={references.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
    

      <AddReferenceDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onChange={handleInputChange}
        onSubmit={handleFormSubmit}
        newReference={newReference}
      />
    </div>
  );
};

export default ReferenceLoan;
