import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';

import UpdateBikeModel from '../../components/bikemodeldata/UpdateBikeModel';
import UpdateStock from '../../components/stockdata/UpdateStock';


const EditStock = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
         <UpdateStock />
      </div>
    </div>
  );
};

export default EditStock;

