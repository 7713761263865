import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import EditFinanceLoan from '../../components/table/salesloandata/EditFinanceLoan';


const SalesEditFinance = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />

         <EditFinanceLoan />
      </div>
    </div>
  );
};

export default SalesEditFinance;

