// src/components/table/MonthlyPaymentsTable.jsx

import React from 'react';
import './MonthlyPaymentsTable.scss';

const MonthlyPaymentsTable = ({ data }) => {
  return (
    <div className="monthlyPaymentsTable">
      <table>
        <thead>
          <tr>
            <th>Month</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([month, value]) => (
            <tr key={month}>
              <td>{month}</td>
              <td>{value.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MonthlyPaymentsTable;
