import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, Typography, TableContainer, TableHead,
  TableRow, Paper, IconButton, Menu, MenuItem, TablePagination
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';

const PaymentTermsDialog = ({ data, onUpdateClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleClick = (event, term) => {
    setAnchorEl(event.currentTarget);
    setSelectedTerm(term);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedTerm(null);
  };

  const handleUpdate = () => {
    onUpdateClick(selectedTerm);
    handleCloseMenu();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'paid':
        return <CheckBoxIcon style={{color:'green'}}/>;
      case 'pending':
        return <CheckBoxOutlineBlankIcon style={{color:'gray'}} />;
      case 'overdue':
        return <CloseIcon  style={{color:'orang'}}/>;
      default:
        return null;
    }
  };

  return (
    <div>
      <Typography variant="h6" style={{marginLeft:'10px'}}>{data.customerName} / Payment Terms Details</Typography>
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Monthly Amount</TableCell>
              {/* <TableCell>Amount Due</TableCell> */}
              <TableCell>Paid Amount</TableCell>
              <TableCell>Balance Amount</TableCell>
              {/* <TableCell>Total Loan Payment</TableCell> */}
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.paymentTerms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => (
              <TableRow key={term._id}>
                <TableCell>{term.installmentNumber}</TableCell>
                <TableCell>{new Date(term.dueDate).toLocaleDateString()}</TableCell>
                {/* <TableCell>{term.amountDue.toFixed(2)}</TableCell> */}
                <TableCell>{term.monthlyInstalmentAmount.toFixed(2)}</TableCell>
                <TableCell>{term.paidAmount?.toFixed(2)}</TableCell>
                <TableCell>{term.balanceAmount?.toFixed(2)}</TableCell>
                {/* <TableCell>{term.totalLoanPayment.toFixed(2)}</TableCell> */}
                <TableCell>{getStatusIcon(term.status)}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => handleClick(event, term)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl && selectedTerm === term)}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={handleUpdate}>Update</MenuItem>
                    {/* Add other actions here if needed */}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={data.paymentTerms.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default PaymentTermsDialog;
