import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import PaymentDataEid from '../../components/paymentermsdata/PaymentDataEid';

const PaymentermsEdit = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
      <PaymentDataEid/>
      </div>
    </div>
  );
};

export default PaymentermsEdit;
