import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import BarChart from '../../components/barChart/BarChart';
import MonthlyPaymentsTable from './MonthlyPaymentsTable';

import './Forecast.scss';

const Forecast = () => {
  const [paymentsByYear, setPaymentsByYear] = useState({});
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURL}/financeloans/state`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setPaymentsByYear(response.data.paymentsByYear);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [apiURL]);

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="charts">
          <div className="chartContainer">
            <h2>Monthly Forecast Sales in Table</h2>
            <div className="yearSelector">
              <label htmlFor="year">Select Year: </label>
              <select id="year" value={selectedYear} onChange={handleYearChange}>
                {Object.keys(paymentsByYear).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <MonthlyPaymentsTable data={paymentsByYear[selectedYear] || {}} />
          </div>
          <div className="tableContainer">
            {/* <h2>Monthly Forecast Sales in Statistics</h2>
            <BarChart data={paymentsByYear[selectedYear] || {}} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forecast;
