import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import List from '../../components/table/List';
import ViewCustomerCash from '../../components/table/ViewCustomerCash';

const Cash = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
         <ViewCustomerCash />
      </div>
    </div>
  );
};

export default Cash;
