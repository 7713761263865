import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Grid, Typography, MenuItem } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditEmploymentLoan = () => {
  const { id } = useParams(); // Get the employment ID from the URL
  const [employmentData, setEmploymentData] = useState({});
  const apiURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmploymentData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const url = `${apiURL}/employmentloans/${id}`;
        console.log('API URL:', url); // Add this line
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setEmploymentData(response.data);
      } catch (error) {
        console.error('Error fetching employment data:', error);
      }
    };

    fetchEmploymentData();
  }, [id, apiURL]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmploymentData({ ...employmentData, [name]: value });
  };

  const handleUpdateEmployment = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      await axios.put(`${apiURL}/employmentloans/${id}`, employmentData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      navigate('/customerloans'); // Redirect back to the employment loans list
    } catch (error) {
      console.error('Error updating employment data:', error);
    }
  };

  return (
    <Paper style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Edit Employment Loan
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Occupation"
            name="occupation"
            value={employmentData.occupation || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Occupation Type"
            name="occupationType"
            value={employmentData.occupationType || ''}
            onChange={handleInputChange}
            fullWidth
            select
          >
            <MenuItem value="Contract">Contract</MenuItem>
            <MenuItem value="Employed">Employed</MenuItem>
            <MenuItem value="Freelance">Freelance</MenuItem>
            <MenuItem value="Part-Time">Part-Time</MenuItem>
            <MenuItem value="Self-Employed">Self-Employed</MenuItem>
            <MenuItem value="Unemployed">Unemployed</MenuItem>
            <MenuItem value="Probation">Probation</MenuItem>
            <MenuItem value="Pensioned">Pensioned</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Employment Length"
            name="employmentLength"
            value={employmentData.employmentLength || ''}
            onChange={handleInputChange}
            fullWidth
            select
          >
            <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
            <MenuItem value="Between 1 and 3 years">Between 1 and 3 years</MenuItem>
            <MenuItem value="Between 3 and 5 years">Between 3 and 5 years</MenuItem>
            <MenuItem value="More than 5 years">More than 5 years</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Employment Segment"
            name="employmentSegment"
            value={employmentData.employmentSegment || ''}
            onChange={handleInputChange}
            fullWidth
            select
          >
            <MenuItem value="GIG(e.g. Grab / FoodPanda)">GIG(e.g. Grab / FoodPanda)</MenuItem>
            <MenuItem value="Police / Army / FireFighter">Police / Army / FireFighter</MenuItem>
            <MenuItem value="Government Staff">Government Staff</MenuItem>
            <MenuItem value="Driver(Lorry, Van, Citylink)">Driver(Lorry, Van, Citylink)</MenuItem>
            <MenuItem value="Security Guard">Security Guard</MenuItem>
            <MenuItem value="Semi-Skilled(Factory, Construction & Technical Worker)">Semi-Skilled(Factory, Construction & Technical Worker)</MenuItem>
            <MenuItem value="Retail(e.g. Sales, F&B, Mart, Fashion, Hotel, Travel)">Retail(e.g. Sales, F&B, Mart, Fashion, Hotel, Travel)</MenuItem>
            <MenuItem value="Office Worker / Admin/ Clerk/ HR">Office Worker / Admin/ Clerk/ HR</MenuItem>
            <MenuItem value="Professional(e.g. Engineer, Banker, Healthcare, IT, Teacher)">Professional(e.g. Engineer, Banker, Healthcare, IT, Teacher)</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Employer Name"
            name="employerName"
            value={employmentData.employerName || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Employer Phone Number"
            name="employerPhoneNumber"
            value={employmentData.employerPhoneNumber || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Supervisor Name"
            name="supervisorName"
            value={employmentData.supervisorName || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Supervisor Phone Number"
            name="supervisorPhoneNumber"
            value={employmentData.supervisorPhoneNumber || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Employer Address"
            name="employerAddress"
            value={employmentData.employerAddress || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Monthly Gross Salary"
            name="monthlyGrossSalary"
            type="number"
            value={employmentData.monthlyGrossSalary || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Monthly Net Salary"
            name="monthlyNetSalary"
            type="number"
            value={employmentData.monthlyNetSalary || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Salary Crediting Bank"
            name="salaryCreditingBank"
            value={employmentData.salaryCreditingBank || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Salary Crediting Account Number"
            name="salaryCreditingAccountNumber"
            value={employmentData.salaryCreditingAccountNumber || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Salary Crediting Day"
            name="salaryCreditingDay"
            value={employmentData.salaryCreditingDay || ''}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleUpdateEmployment}
            sx={{
              backgroundColor: '#037b7d',
              color: '#ffffff',

            }}
          >
            Update Employment
          </Button>

        </Grid>
      </Grid>
    </Paper>
  );
};

export default EditEmploymentLoan;
