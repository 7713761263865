import React, { useState } from 'react';
import { TextField, Button, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import axios from 'axios';

const AddScore = () => {
  const initialScoreItem = { title: '', score: 0, scoreOutOf: 10 };
  const [scoreItems, setScoreItems] = useState([
    { ...initialScoreItem, title: 'Title 1' },
  ]);

  const handleScoreChange = (index, field, value) => {
    const updatedItems = [...scoreItems];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setScoreItems(updatedItems);
  };

  const handleTitleChange = (index, value) => {
    const updatedItems = [...scoreItems];
    updatedItems[index] = { ...updatedItems[index], title: value };
    setScoreItems(updatedItems);
  };

  const handleAddScoreItem = () => {
    setScoreItems([...scoreItems, { ...initialScoreItem, title: `Title ${scoreItems.length + 1}` }]);
  };

  const handleSaveScores = async () => {
    const scoresToSend = scoreItems.map(score => ({
      title: score.title,
      score: Number(score.score),
      scoreOutOf: Number(score.scoreOutOf)
    }));

    try {
      const response = await axios.post('http://192.168.0.139:5000/api/scores', {
        // Assuming `newReference` is defined somewhere in your actual code
        scores: scoresToSend
      });
      console.log('Scores created:', response.data);
      // Additional logic such as onSubmit() or setting tab index can be added here
    } catch (error) {
      console.error('Error creating scores:', error);
    }
  };

  const totalScore = scoreItems.reduce((total, item) => total + Number(item.score), 0);
  const totalOutOf = scoreItems.reduce((total, item) => total + Number(item.scoreOutOf), 0);

  return (
    <div>
      <Typography variant="h4">Score Management</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Index</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Score</TableCell>
            <TableCell>Score Out Of</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scoreItems.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={item.title}
                  onChange={(e) => handleTitleChange(index, e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={item.score}
                  onChange={(e) => handleScoreChange(index, 'score', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={item.scoreOutOf}
                  onChange={(e) => handleScoreChange(index, 'scoreOutOf', e.target.value)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant="h6">Total Score: {totalScore} / {totalOutOf}</Typography>
      <Button onClick={handleAddScoreItem} variant="contained" color="secondary">
        Add Score Item
      </Button>
      <Button onClick={handleSaveScores} variant="contained" color="primary">
        Save Scores
      </Button>
    </div>
  );
};

export default AddScore;
