import React, { useState, useEffect } from 'react';
import {
  Box, Accordion, AccordionSummary,
  AccordionDetails, TextField, Button, Typography, InputAdornment,
  MenuItem,
  Container
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import './new.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import UploadImageCash from '../../components/UploadImageCash';
import { MobileNumberMask, NRICNoMask, PhoneNumberMask } from './MobileNumberMask';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  customButton: {
    backgroundColor: '#037b7d', 
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2c9294', 
    },
  },
});

const NewCustomercash = () => {
  const [customerId, setCustomerId] = useState(null);
  const classes = useStyles();
  const apiURL = process.env.REACT_APP_API_URL;

  const [customerDetails, setCustomerDetails] = useState({
    cashType: '',
    name: '',
    nricNo: '',
    mobileNumber: '',
    bestTimeToCall: '',
    email: '',
    gender: '',
    maritalStatus: '',
    race: '',
    address: '',
    city: '',
    postcode: '',
    state: '',
  });

  const [employmentDetails, setEmploymentDetails] = useState({
    occupation: '',
    occupationType: '',
    employmentLength: '',
    employmentSegment: '',
    employerName: '',
    employerPhoneNumber: '',
    supervisorName: '',
    supervisorPhoneNumber: '',
    employerAddress: '',
    city: '',
    postcode: '',
    state: '',
    monthlyGrossSalary: '',
    monthlyNetSalary: '',
    salaryCreditingBank: '',
    salaryCreditingAccountNumber: '',
    salaryCreditingDay: '',
    epf: '',
  });

  const [attachmentLoan, setAttachmentLoan] = useState({
    nric: { front: '', back: '' },
    licence: { front: '', back: '' },
  });

  const handleInputChange = (section, field, value) => {
    switch (section) {
      case 'customerDetails':
        setCustomerDetails({ ...customerDetails, [field]: value });
        break;
      case 'employmentDetails':
        setEmploymentDetails({ ...employmentDetails, [field]: value });
        break;
      case 'attachmentLoan':
        if (['nric', 'licence'].includes(field)) {
          setAttachmentLoan({
            ...attachmentLoan,
            [field]: { ...attachmentLoan[field], ...value },
          });
        } else {
          setAttachmentLoan({ ...attachmentLoan, [field]: value });
        }
        break;
      default:
        break;
    }
  };
  const [saveStatus, setSaveStatus] = useState({
    customerDetails: { disabled: false, color: 'primary' },
    employmentDetails: { disabled: false, color: 'primary' },
    attachmentLoan: { disabled: false, color: 'primary' },
  });

  const handleSave = async (section) => {
    try {
      let data;
      let url;
      switch (section) {
        case 'customerDetails':
          data = customerDetails;
          url = `${apiURL}/customercashs`;
          const storedToken = localStorage.getItem('token');
          const customerResponse = await axios.post(url, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${storedToken}`,
            },
          });
          const newCustomer = customerResponse.data;
          setCustomerId(newCustomer._id);
          setSaveStatus({
            ...saveStatus,
            customerDetails: { disabled: true, color: 'warning' }, // Change color to 'warning' (light orange)
          });
          alert('Customer details saved successfully!');
          break;
        case 'employmentDetails':
          if (!customerId) {
            alert('Please save customer details first.');
            return;
          }
          data = { ...employmentDetails, customerId };
          url = `${apiURL}/employmentcashs`;
          break;
        case 'attachmentLoan':
          if (!customerId) {
            alert('Please save customer details first.');
            return;
          }
          data = { ...attachmentLoan, customerId };
          url = `${apiURL}/attachmentcashs`;
          break;
        default:
          return;
      }

      if (section !== 'customerDetails') {
        const storedToken = localStorage.getItem('token');
        const response = await axios.post(url, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const newRecord = response.data;
        console.log(`${section} saved successfully:`, newRecord);
        setSaveStatus({
          ...saveStatus,
          [section]: { disabled: true, color: 'warning' }, // Change color to 'warning' (light orange)
        });
        alert(`${section.replace(/([A-Z])/g, ' $1')} saved successfully!`);
      }
    } catch (error) {
      console.error(`Error saving ${section}:`, error);
      alert(`Error saving ${section}: ${error.message}`);
    }
  };

  const handleUploadComplete = (uploadedFiles) => {
    handleInputChange('attachmentLoan', 'nric', {
      front: uploadedFiles.nricFront,
      back: uploadedFiles.nricBack,
    });
    handleInputChange('attachmentLoan', 'licence', {
      front: uploadedFiles.licenceFront,
      back: uploadedFiles.licenceBack,
    });
  };

  return (
    <div className="new">
      <div className="addtable">
        <Sidebar />
        <div className="newContainer">
          <Navbar />
          <div className="bottom"></div>
          <Container component="main" maxWidth="lg">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ px: 2 }}
            >
              <Box
                width="100%"
                maxWidth="800px"
                p={3}
                boxShadow={3}
                borderRadius={2}
                bgcolor="background.paper"
              >
                <h1>New Customer Cash</h1>
                <div className="formContainer">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Customer Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <TextField
                          label="Cash Type"
                          value={customerDetails.cashType}
                          onChange={(e) => handleInputChange('customerDetails', 'cashType', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="SLH">SLH</MenuItem>
                          <MenuItem value="AEON">AEON</MenuItem>
                          <MenuItem value="JOL">JOL</MenuItem>
                        </TextField>

                        <TextField
                          label="Name"
                          value={customerDetails.name}
                          onChange={(e) => handleInputChange('customerDetails', 'name', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="NRIC No"
                          value={customerDetails.nricNo}
                          onChange={(e) => handleInputChange('customerDetails', 'nricNo', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                          InputProps={{
                            inputComponent: NRICNoMask,
                          }}
                        />
                        <TextField
                          label="Mobile Number"
                          value={customerDetails.mobileNumber}
                          onChange={(e) => handleInputChange('customerDetails', 'mobileNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                          InputProps={{
                            inputComponent: MobileNumberMask,
                          }}
                        />
                        <TextField
                          label="Best Time To Call"
                          value={customerDetails.bestTimeToCall}
                          onChange={(e) => handleInputChange('customerDetails', 'bestTimeToCall', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="Email"
                          value={customerDetails.email}
                          onChange={(e) => handleInputChange('customerDetails', 'email', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="Gender"
                          value={customerDetails.gender}
                          onChange={(e) => handleInputChange('customerDetails', 'gender', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                        <TextField
                          label="Marital Status"
                          value={customerDetails.maritalStatus}
                          onChange={(e) => handleInputChange('customerDetails', 'maritalStatus', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="single">Single</MenuItem>
                          <MenuItem value="married">Married</MenuItem>
                          <MenuItem value="divorced">Divorced</MenuItem>
                          <MenuItem value="separated">Separated</MenuItem>
                          <MenuItem value="widowed">Widowed</MenuItem>
                          <MenuItem value="registered partnership">Registered Partnership</MenuItem>
                        </TextField>
                        <TextField
                          label="Race"
                          value={customerDetails.race}
                          onChange={(e) => handleInputChange('customerDetails', 'race', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="Malay">Malay</MenuItem>
                          <MenuItem value="Chinese">Chinese</MenuItem>
                          <MenuItem value="Indian">Indian</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                        <TextField
                          label="Address"
                          value={customerDetails.address}
                          onChange={(e) => handleInputChange('customerDetails', 'address', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="City"
                          value={customerDetails.city}
                          onChange={(e) => handleInputChange('customerDetails', 'city', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="Postcode"
                          value={customerDetails.postcode}
                          onChange={(e) => handleInputChange('customerDetails', 'postcode', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="State"
                          value={customerDetails.state}
                          onChange={(e) => handleInputChange('customerDetails', 'state', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="Johor">Johor</MenuItem>
                          <MenuItem value="Kedah">Kedah</MenuItem>
                          <MenuItem value="Kelantan">Kelantan</MenuItem>
                          <MenuItem value="Malacca">Malacca</MenuItem>
                          <MenuItem value="Negeri Sembilan">Negeri Sembilan</MenuItem>
                          <MenuItem value="Pahang">Pahang</MenuItem>
                          <MenuItem value="Penang">Penang</MenuItem>
                          <MenuItem value="Perlis">Perlis</MenuItem>
                          <MenuItem value="Perak">Perak</MenuItem>
                          <MenuItem value="Sabah">Sabah</MenuItem>
                          <MenuItem value="Sarawak">Sarawak</MenuItem>
                          <MenuItem value="Selangor">Selangor</MenuItem>
                          <MenuItem value="Terengganu">Terengganu</MenuItem>
                        </TextField>
                        <Button
                          variant="contained"
                          className={classes.customButton}
                          color={saveStatus.customerDetails.color}
                          disabled={saveStatus.customerDetails.disabled}
                          onClick={() => handleSave('customerDetails')}
                        >
                          Save Customer Details
                        </Button>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Employment Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <TextField
                          label="Occupation"
                          value={employmentDetails.occupation}
                          onChange={(e) => handleInputChange('employmentDetails', 'occupation', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Occupation Type"
                          value={employmentDetails.occupationType}
                          onChange={(e) => handleInputChange('employmentDetails', 'occupationType', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Contract">Contract</MenuItem>
                          <MenuItem value="Employed">Employed</MenuItem>
                          <MenuItem value="Freelance">Freelance</MenuItem>
                          <MenuItem value="Part-Time">Part-Time</MenuItem>
                          <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                          <MenuItem value="Unemployed">Unemployed</MenuItem>
                          <MenuItem value="Probation">Probation</MenuItem>
                          <MenuItem value="Pensioned">Pensioned</MenuItem>
                        </TextField>
                        <TextField
                          label="Employment Length"
                          value={employmentDetails.employmentLength}
                          onChange={(e) => handleInputChange('employmentDetails', 'employmentLength', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
                          <MenuItem value="Between 1 and 3 years">Between 1 and 3 years</MenuItem>
                          <MenuItem value="Between 3 and 5 years">Between 3 and 5 years</MenuItem>
                          <MenuItem value="More than 5 years">More than 5 years</MenuItem>
                        </TextField>
                        <TextField
                          label="Employment Segment"
                          value={employmentDetails.employmentSegment}
                          onChange={(e) => handleInputChange('employmentDetails', 'employmentSegment', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="GIG(e.g. Grab / FoodPanda)">GIG(e.g. Grab / FoodPanda)</MenuItem>
                          <MenuItem value="Police / Army / FireFighter">Police / Army / FireFighter</MenuItem>
                          <MenuItem value="Government Staff">Government Staff</MenuItem>
                          <MenuItem value="Driver(Lorry, Van, Citylink)">Driver(Lorry, Van, Citylink)</MenuItem>
                          <MenuItem value="Security Guard">Security Guard</MenuItem>
                          <MenuItem value="Semi-Skilled(Factory, Construction & Technical Worker)">Semi-Skilled(Factory, Construction & Technical Worker)</MenuItem>
                          <MenuItem value="Retail(e.g. Sales, F&B, Mart, Fashion, Hotel, Travel)">Retail(e.g. Sales, F&B, Mart, Fashion, Hotel, Travel)</MenuItem>
                          <MenuItem value="Office Worker / Admin/ Clerk/ HR">Office Worker / Admin/ Clerk/ HR</MenuItem>
                          <MenuItem value="Professional(e.g. Engineer, Banker, Healthcare, IT, Teacher)">Professional(e.g. Engineer, Banker, Healthcare, IT, Teacher)</MenuItem>
                        </TextField>
                        <TextField
                          label="Employer Name"
                          value={employmentDetails.employerName}
                          onChange={(e) => handleInputChange('employmentDetails', 'employerName', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Employer Telephone Number"
                          value={employmentDetails.employerPhoneNumber}
                          onChange={(e) => handleInputChange('employmentDetails', 'employerPhoneNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                          InputProps={{
                            inputComponent: PhoneNumberMask,
                          }}
                        />
                        <TextField
                          label="Supervisor Name"
                          value={employmentDetails.supervisorName}
                          onChange={(e) => handleInputChange('employmentDetails', 'supervisorName', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Supervisor Telephone Number"
                          value={employmentDetails.supervisorPhoneNumber}
                          onChange={(e) => handleInputChange('employmentDetails', 'supervisorPhoneNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                          InputProps={{
                            inputComponent: PhoneNumberMask,
                          }}
                        />
                        <TextField
                          label="Employer Address"
                          value={employmentDetails.employerAddress}
                          onChange={(e) => handleInputChange('employmentDetails', 'employerAddress', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="City"
                          value={employmentDetails.city}
                          onChange={(e) => handleInputChange('employmentDetails', 'city', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Postcode"
                          value={employmentDetails.postcode}
                          onChange={(e) => handleInputChange('employmentDetails', 'postcode', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="State"
                          value={employmentDetails.state}
                          onChange={(e) => handleInputChange('employmentDetails', 'state', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Johor">Johor</MenuItem>
                          <MenuItem value="Kedah">Kedah</MenuItem>
                          <MenuItem value="Kelantan">Kelantan</MenuItem>
                          <MenuItem value="Malacca">Malacca</MenuItem>
                          <MenuItem value="Negeri Sembilan">Negeri Sembilan</MenuItem>
                          <MenuItem value="Pahang">Pahang</MenuItem>
                          <MenuItem value="Penang">Penang</MenuItem>
                          <MenuItem value="Perlis">Perlis</MenuItem>
                          <MenuItem value="Perak">Perak</MenuItem>
                          <MenuItem value="Sabah">Sabah</MenuItem>
                          <MenuItem value="Sarawak">Sarawak</MenuItem>
                          <MenuItem value="Selangor">Selangor</MenuItem>
                          <MenuItem value="Terengganu">Terengganu</MenuItem>
                        </TextField>
                        <TextField
                          label="Monthly Gross Salary"
                          value={employmentDetails.monthlyGrossSalary}
                          onChange={(e) => handleInputChange('employmentDetails', 'monthlyGrossSalary', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Monthly Net Salary"
                          value={employmentDetails.monthlyNetSalary}
                          onChange={(e) => handleInputChange('employmentDetails', 'monthlyNetSalary', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Salary Crediting Bank"
                          value={employmentDetails.salaryCreditingBank}
                          onChange={(e) => handleInputChange('employmentDetails', 'salaryCreditingBank', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Affin Bank Berhad">Affin Bank Berhad</MenuItem>
                          <MenuItem value="Affin Islamic Bank Berhad">Affin Islamic Bank Berhad</MenuItem>
                          <MenuItem value="Alliance Bank Malaysia Berhad">Alliance Bank Malaysia Berhad</MenuItem>
                          <MenuItem value="Alliance Islamic Bank Malaysia Berhad">Alliance Islamic Bank Malaysia Berhad</MenuItem>
                          <MenuItem value="Al Rajhi Banking & Investment Corporation (Malaysia) Berhad">Al Rajhi Banking & Investment Corporation (Malaysia) Berhad</MenuItem>
                          <MenuItem value="AmBank (M) Berhad">AmBank (M) Berhad</MenuItem>
                          <MenuItem value="Bank Islam Malaysia Berhad">Bank Islam Malaysia Berhad</MenuItem>
                          <MenuItem value="Bank Kerjasama Rakyat Malaysia Berhad">Bank Kerjasama Rakyat Malaysia Berhad</MenuItem>
                          <MenuItem value="Bank Muamalat Malaysia Berhad">Bank Muamalat Malaysia Berhad</MenuItem>
                          <MenuItem value="Bank of China (Malaysia) Berhad">Bank of China (Malaysia) Berhad</MenuItem>
                          <MenuItem value="Bank Pertanian Malaysia Berhad (Agrobank)">Bank Pertanian Malaysia Berhad (Agrobank)</MenuItem>
                          <MenuItem value="Bank Simpanan Nasional">Bank Simpanan Nasional</MenuItem>
                          <MenuItem value="CIMB Bank Berhad">CIMB Bank Berhad</MenuItem>
                          <MenuItem value="CIMB Islamic Bank Berhad">CIMB Islamic Bank Berhad</MenuItem>
                          <MenuItem value="Citibank Berhad">Citibank Berhad</MenuItem>
                          <MenuItem value="Hong Leong Bank Berhad">Hong Leong Bank Berhad</MenuItem>
                          <MenuItem value="Hong Leong Islamic Bank Berhad">Hong Leong Islamic Bank Berhad</MenuItem>
                          <MenuItem value="HSBC Amanah Malaysia Berhad">HSBC Amanah Malaysia Berhad</MenuItem>
                          <MenuItem value="HSBC Bank Malaysia Berhad">HSBC Bank Malaysia Berhad</MenuItem>
                          <MenuItem value="Industrial and Commercial Bank of China (Malaysia) Berhad">Industrial and Commercial Bank of China (Malaysia) Berhad</MenuItem>
                          <MenuItem value="Kuwait Finance House">Kuwait Finance House</MenuItem>
                          <MenuItem value="Malayan Banking Berhad">Malayan Banking Berhad</MenuItem>
                          <MenuItem value="MBSB Bank Berhad">MBSB Bank Berhad</MenuItem>
                          <MenuItem value="OCBC Bank (Malaysia) Berhad">OCBC Bank (Malaysia) Berhad</MenuItem>
                          <MenuItem value="Public Bank Berhad">Public Bank Berhad</MenuItem>
                          <MenuItem value="RHB Bank Berhad">RHB Bank Berhad</MenuItem>
                          <MenuItem value="RHB Islamic Bank Berhad">RHB Islamic Bank Berhad</MenuItem>
                          <MenuItem value="Standard Chartered Bank Malaysia Berhad">Standard Chartered Bank Malaysia Berhad</MenuItem>
                          <MenuItem value="Standard Chartered Saadiq Berhad">Standard Chartered Saadiq Berhad</MenuItem>
                          <MenuItem value="United Overseas Bank (Malaysia) Berhad">United Overseas Bank (Malaysia) Berhad</MenuItem>
                        </TextField>
                        <TextField
                          label="Salary Crediting Account Number"
                          value={employmentDetails.salaryCreditingAccountNumber}
                          onChange={(e) => handleInputChange('employmentDetails', 'salaryCreditingAccountNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Salary Crediting Day"
                          type="date"
                          value={employmentDetails.salaryCreditingDay}
                          onChange={(e) => handleInputChange('employmentDetails', 'salaryCreditingDay', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TextField
                          label="Customer with EPF"
                          value={employmentDetails.epf}
                          onChange={(e) => handleInputChange('employmentDetails', 'epf', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </TextField>
                        <Button
                          variant="contained"
                          className={classes.customButton}
                          color={saveStatus.employmentDetails.color}
                          disabled={saveStatus.employmentDetails.disabled}
                          onClick={() => handleSave('employmentDetails')}
                        >
                          Save Employment Details
                        </Button>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Attachment Loan</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <UploadImageCash customerId={customerId} onComplete={handleUploadComplete} />

                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                </Box>
                </Box>
              </Container>
            </div>
        </div>
      </div>
      );
};

      export default NewCustomercash;
