import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl,
  InputLabel, Select, MenuItem, Snackbar, Alert
} from '@mui/material';
import axios from 'axios';

const UpdatePaymentTerms = ({ open, handleClose, term }) => {
  const [status, setStatus] = useState(term?.status || '');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const apiURL = process.env.REACT_APP_API_URL;

  const handleUpdate = async () => {
    try {
        console.log('Updating...');
        const storedToken = localStorage.getItem('token');
        const response = await axios.put(`${apiURL}/paymenterms/${term._id}`, 
        {
            status: status, // Payload data
        },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${storedToken}`,
            }
        });
        console.log('Update Response:', response.data);
        setSnackbarMessage('Update successful!');
        setSnackbarOpen(true);
        handleClose();
    } catch (error) {
        console.error('Error updating payment term:', error);
        setSnackbarMessage('Error updating payment term');
        setSnackbarOpen(true);
    }
};


  const handleSnackbarClose = () => {
    console.log('Snackbar closed');
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Payment Term</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate} color="primary">Update payment</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('Error') ? 'error' : 'success'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UpdatePaymentTerms;
